import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';
import Button from 'components/Form/Button';
import { Trans, useTranslation } from 'react-i18next';
import Form, { InputField } from 'components/Form';
import { LoginDTO } from 'dto/user';
import { LoginInput } from 'components/Form/Input';
import './login.css';

interface Props {
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSignup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setShowForgotUsername: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

export default observer(({ setShowLogin, setShowSignup, show, setShowForgotPassword, setShowForgotUsername }: Props) => {
  const { authStore } = useStores();
  const { t } = useTranslation('login');
  const history = useHistory();
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const switchToSignup = () => {
    setShowLogin(false);
    setShowForgotPassword(false);
    setShowForgotUsername(false);
    setShowSignup(true);
  };

  const switchToForgotPassword = () => {
    setShowLogin(false);
    setShowSignup(false);
    setShowForgotUsername(false);
    setShowForgotPassword(true);
  };

  const switchToForgotUsername = () => {
    setShowLogin(false);
    setShowSignup(false);
    setShowForgotPassword(false);
    setShowForgotUsername(true);
  };
  const initialValues: LoginDTO = {
    username: '',
    password: ''
  };

  const getNextParam = useCallback(() => {
    if (!history.location.search) return null;
    const searchArray = history.location.search.split('?next=');
    if (searchArray.length <= 1) return null;
    return searchArray[1];
  }, [history.location.search]);

  const handleLogin = (login: LoginDTO) => {
    authStore.checkLogin(login.username, login.password).then(result => {
      if (!result) {
        setShowErrorMsg(true);
      } else {
        setShowErrorMsg(false);
        const next = getNextParam();
        if (next) {
          history.push('/guides');
          history.push(next);
        } else {
          history.push('/guides');
        }
      }
    });
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) {
      submitMyForm();
    }
  };

  useEffect(() => {
    if (!authStore.isLoggedIn) {
      authStore.autoDevelopLogin().then(result => {
        if (result) {
          const next = getNextParam();
          if (next) {
            history.push(next);
          } else {
            history.push('/guides');
          }
        }
      });
    }
  }, [history, authStore, getNextParam]);

  return (
    <>
      <div className={`div-block-170 ${!show ? 'hide' : ''}`}>
        <div className="div-block-178">
          <div className="div-block-171">
            <img src="images/logo_klinik-puls.svg" alt="" />
            <div className="txt_logo-copy">
              <strong>{t('logoTextFirstPart')}</strong>
              {t('logoTextSecondPart')}
            </div>
          </div>

          <div className="headline-login">{t('headline')}</div>
          <div className="login_wrapper">
            <div className="w-form">
              {show ? ( // force re-render of form when show changes to true
                <Form
                  initialValues={initialValues}
                  className="form"
                  name="email-form"
                  bindSubmitForm={bindSubmitForm}
                  onSubmit={handleLogin}
                >
                  {() => {
                    return (
                      <>
                        <div className="div-block-172">
                          <img src="images/icon_single-01_16_grey.svg" loading="lazy" alt="" className="image-11" />
                          <label className="field-label" htmlFor="username">
                            {t('username')}
                          </label>
                          <InputField autoFocus component={LoginInput} type="text" maxLength={256} name="username" />
                        </div>
                        <div className="div-block-172">
                          <img src="images/icon_key_16_grey.svg" loading="lazy" alt="" className="image-11" />
                          <label className="field-label" htmlFor="password">
                            {t('password')}
                          </label>
                          <InputField component={LoginInput} type="password" maxLength={256} name="password" />
                        </div>
                        <div className="text-block-26">
                          <Trans i18nKey="login:passwordReset">
                            <span className="text-span-6" onClick={switchToForgotPassword} />
                            <span className="text-span-6" onClick={switchToForgotUsername} />
                          </Trans>
                        </div>
                        <Button className="btn_big_add active w-inline-block submit-btn" type="submit" onSubmit={handleSubmitMyForm}>
                          {t('submit')}
                        </Button>
                      </>
                    );
                  }}
                </Form>
              ) : (
                <></>
              )}
              <div className="div-block-173">
                <div>{t('noAccess')}</div>
                <div onClick={switchToSignup}>
                  <span className="text-span-6">{t('signupLink')}</span>
                </div>
              </div>
            </div>
            {showErrorMsg ? (
              <div id="Desinfektion" className="failure">
                {t('errorMessage')}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
