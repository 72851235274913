import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { UsedMaterialDTO } from 'dto/usedMaterial';
import { useStores } from 'util/mobx/stores';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onOpenDetails: () => void;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  usedMaterial?: UsedMaterialDTO;
}

const SurgerySingleInstrumentMenu = ({ isOpen, handleClose, surgeryGuide, usedMaterial, onOpenDetails }: Props) => {
  const { t } = useTranslation('briefing');
  const { surgeryGuideStore } = useStores();

  const onAddLotNumber = () => surgeryGuideStore.setIsEditLotNumberFlyoutOpen(true, surgeryGuide, usedMaterial);

  return (
    <>
      <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
        <ItemMenuButton
          label={t('briefingMaterials.surgeryMaterialItem.menu.openDetails')}
          icon="icon_search-content_16.svg"
          isInPopover
          handleClick={onOpenDetails}
        />

        <ItemMenuButton
          label={t('briefingMaterials.surgeryMaterialItem.menu.addLotNumber')}
          icon="icon_i-edit_16.svg"
          isInPopover
          handleClick={onAddLotNumber}
        />
      </ItemMenu>
    </>
  );
};

export default SurgerySingleInstrumentMenu;
