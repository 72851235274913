import React from 'react';
import { PackageDTO } from 'dto/package';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import Popover from 'components/Popover';
import GetCameraImageButton from 'components/GetCameraImageButton';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import MaterialValueTags from 'components/MaterialValueTags';
import HoverWrapper from 'components/HoverWrapper';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import Picture from 'components/Picture';
import { useStores } from 'util/mobx/stores';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryInstrumentListItem from '../SurgeryInstrumentListItem';
import SurgeryPackageListItemMenu from './SurgeryPackageListItemMenu';
import SurgeryMaterialCheckItem from '../SurgeryMaterialCheckItem';

import './SurgeryPackageListItem.css';

interface Props extends OptionalLazyLoadProps {
  packageItem: PackageDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  dropdownContainerIndex: number;
  onAddOneCheck: (packageId: string) => void;
  onRemoveOneCheck: (packageId: string) => void;
}

const SurgeryPackageListItem = ({
  packageItem,
  surgeryGuide,
  dropdownContainerIndex,
  onAddOneCheck,
  onRemoveOneCheck,
  lazyLoadScrollContainer
}: Props) => {
  const { surgeryGuideStore, guideDetailDrawerStore, instrumentStore } = useStores();

  const onOpenDetails = () => {
    guideDetailDrawerStore.setSelectedPackage(packageItem);
    guideDetailDrawerStore.setSelectedSurgeryGuideItem(surgeryGuide);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const onUpdateFile = async (fileId: string) => {
    const pictureFileIds = packageItem.pictures.map((file, index) => {
      if (index === 0) {
        return fileId;
      }
      return file.pictureFileId;
    });
    if (pictureFileIds.length === 0) {
      pictureFileIds.push(fileId);
    }

    await instrumentStore.updatePackage({ pictureFileIds, packageId: packageItem.packageId });
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideStore.surgeryGuide.surgeryGuideId);
    }
  };

  const renderList = () => (
    <div className="content_holder no_padding padding_left in_matlist instruments-list" style={{ zIndex: dropdownContainerIndex }}>
      {packageItem.instruments.map(instrument => (
        <SurgeryInstrumentListItem
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          instrument={instrument}
          key={instrument.instrumentId}
          surgeryGuide={surgeryGuide}
        />
      ))}
    </div>
  );

  const usedMaterial = surgeryGuide.usedMaterials.find(m => m.packageId === packageItem.packageId);

  const onStatusChange = (status: UsedMaterialStatus) => {
    if (status === UsedMaterialStatus.None) {
      onRemoveOneCheck(packageItem.packageId);
    } else {
      onAddOneCheck(packageItem.packageId);
    }
    surgeryGuideStore.setUsedMaterial({
      surgeryGuideMaterialId: surgeryGuide.surgeryGuideMaterialId,
      packageId: packageItem.packageId,
      status
    });
  };

  return (
    <HoverWrapper>
      {({ hover }) => (
        <ListItemDropdownContainer
          classPrefix="package"
          className="surgery-package"
          ListElement={renderList}
          inGuide
          style={{ zIndex: dropdownContainerIndex }}
        >
          <>
            <div className="item_count _1_lvl hidden" onClick={onOpenDetails} />
            <div className="item_dropdown_holder" onClick={onOpenDetails} />
            <div className="image_wrapper_50">
              <SurgeryMaterialCheckItem
                inSublist
                onStatusChanged={onStatusChange}
                status={usedMaterial?.status}
                surgeryGuide={surgeryGuide}
                usedMaterial={usedMaterial}
              />
              {packageItem.pictures.length > 0 ? (
                <Picture
                  withBorder
                  size="pictureThumbnail"
                  src={packageItem.pictures[0]}
                  width={50}
                  alt=""
                  lazyLoadScrollContainer={lazyLoadScrollContainer}
                  className="image_circle_50"
                />
              ) : (
                <GetCameraImageButton buttonStyle="icon" description="" picturesOnly onUpdateFile={onUpdateFile} isAddable withLoadingBar />
              )}
              <div className="material_lvl1_line">
                <div className="dot in_mat_list" />
              </div>
            </div>
            <div className="material_info w-inline-block package-info">
              <div className="material_text material-text" onClick={onOpenDetails}>
                <div>{packageItem.name}</div>
              </div>
              <MaterialValueTags pack={packageItem} />
              <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                {({ handleClose, isOpen }) => (
                  <SurgeryPackageListItemMenu
                    isOpen={!!isOpen}
                    handleClose={handleClose}
                    surgeryGuide={surgeryGuide}
                    usedMaterial={usedMaterial}
                    onOpenDetails={onOpenDetails}
                  />
                )}
              </Popover>
            </div>
          </>
        </ListItemDropdownContainer>
      )}
    </HoverWrapper>
  );
};

export default SurgeryPackageListItem;
