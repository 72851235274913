import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { GuideMaterialLikeDTO } from 'dto/guide';
import { getItemStyle } from 'util/dragAndDrop';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { GuideTemplateListItem, GuideMaterialListItem, MaterialSetListItem } from 'components/ListItems';
import { subject } from '@casl/ability';
import { useStores } from 'util/mobx/stores';
import { SetType } from 'dto/materialSet';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';

interface Props extends OptionalLazyLoadProps {
  materials: GuideMaterialLikeDTO[];
  isClone?: boolean;
  noDrag?: boolean;
}
const GuideMaterialList = ({ materials, isClone = false, noDrag = false, lazyLoadScrollContainer }: Props) => {
  const { guideStore } = useStores();
  const materialList = (guideData: GuideMaterialLikeDTO) => {
    if (guideData.material) {
      return (
        <GuideMaterialListItem
          material={guideData.material}
          guideData={guideData}
          listItemMaterialClass="list_item_material"
          key={guideData.guideMaterialId}
          lazyLoadScrollContainer={lazyLoadScrollContainer}
        />
      );
    }
    return null;
  };

  /**
   * adds the Draggable to the child only if the noDrag prop is false
   *
   * @param component a simple component which is either wrapped in a draggable or in a simple div
   * @param guideMaterialId needed for the draggable
   * @param index needed for the draggable
   */
  const optionallyWrapInDraggable = (component: JSX.Element | null, guideMaterialId: string, index: number): JSX.Element => {
    if (noDrag) {
      return <div>{component}</div>;
    }
    return (
      <Draggable draggableId={guideMaterialId} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {component}
          </div>
        )}
      </Draggable>
    );
  };

  const createMaterialSetListItem = (guideData: GuideMaterialLikeDTO) => {
    if (guideData.materialSet) {
      return (
        <MaterialSetListItem
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          materialSet={guideData.materialSet}
          guideData={guideData}
          isClone={isClone}
          inGuide
          className={isClone ? 'list_item_material_no_hover in_flyout' : ''}
          storageLocationMissingIcon={!guideData.storageLocationId && guideData.materialSet.type !== SetType.Medicals}
        />
      );
    }
    return <> </>;
  };

  return (
    <>
      {materials.map((guideData, index) => {
        if (guideData.materialSet) {
          return (
            <>
              <Can
                I={actions.dragAndDrop}
                this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}
                key={guideData.guideMaterialId}
              >
                {optionallyWrapInDraggable(createMaterialSetListItem(guideData), guideData.guideMaterialId, index)}
              </Can>

              <Can
                not
                I={actions.dragAndDrop}
                this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}
              >
                {createMaterialSetListItem(guideData)}
              </Can>
            </>
          );
        }
        if (guideData.template) {
          return (
            <>
              <Can
                I={actions.dragAndDrop}
                this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}
                key={guideData.guideMaterialId}
              >
                {optionallyWrapInDraggable(
                  guideData.template ? (
                    <GuideTemplateListItem
                      lazyLoadScrollContainer={lazyLoadScrollContainer}
                      template={guideData.template}
                      guideData={guideData}
                      className={isClone ? 'list_item_material_no_hover in_flyout' : ''}
                      isClone={isClone}
                    />
                  ) : (
                    <></>
                  ),
                  guideData.guideMaterialId,
                  index
                )}
              </Can>

              <Can
                not
                I={actions.dragAndDrop}
                this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}
              >
                {guideData.template && (
                  <GuideTemplateListItem
                    lazyLoadScrollContainer={lazyLoadScrollContainer}
                    template={guideData.template}
                    guideData={guideData}
                    className={isClone ? 'list_item_material_no_hover in_flyout' : ''}
                    isClone={isClone}
                  />
                )}
              </Can>
            </>
          );
        }
        if (guideData.material) {
          return (
            <React.Fragment key={guideData.guideMaterialId}>
              {isClone ? (
                <GuideMaterialListItem
                  material={guideData.material}
                  guideData={guideData}
                  listItemMaterialClass="list_item_material_no_hover in_flyout"
                  isClone
                />
              ) : (
                <>
                  <Can
                    I={actions.dragAndDrop}
                    this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}
                  >
                    {optionallyWrapInDraggable(materialList(guideData), guideData.guideMaterialId, index)}
                  </Can>

                  <Can
                    not
                    I={actions.dragAndDrop}
                    this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}
                  >
                    {guideData && materialList(guideData)}
                  </Can>
                </>
              )}
            </React.Fragment>
          );
        }
        return null;
      })}
    </>
  );
};

export default GuideMaterialList;
