import React, { useEffect, useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import animationCheckData from 'components/ListItems/EditableGroupMaterialListItem/CheckListItemLottie/animationData.json';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { UsedMaterialDTO, UsedMaterialStatus } from 'dto/usedMaterial';
import animationReverseCheckData from './checkbox_reverse.json';
import SurgeryMaterialCheckDropdown from '../SurgeryMaterialCheckDropdown';

interface Props extends React.HTMLProps<HTMLDivElement> {
  status?: UsedMaterialStatus;
  checked?: boolean;
  onStatusChanged: (status: UsedMaterialStatus) => void;
  onCheckChanged?: (status: boolean) => void;
  notEditable?: boolean;
  noCheck?: boolean;
  inSublist?: boolean;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  usedMaterial?: UsedMaterialDTO;
}

const SurgeryMaterialCheckItem = ({
  status,
  onStatusChanged,
  notEditable = false,
  noCheck = false,
  inSublist = false,
  surgeryGuide,
  usedMaterial,
  ...rest
}: Props) => {
  const [isAnimationPaused, setIsAnimationPaused] = useState(true);
  const [lastStatus, setLastStatus] = useState(status);
  const [lastStatusForLottie, setLastStatusForLottie] = useState(status);
  const defaultOptions: LottieProps['options'] = {
    autoplay: false,
    loop: false,
    animationData: lastStatusForLottie === UsedMaterialStatus.Prepared ? animationReverseCheckData : animationCheckData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    setLastStatus(status);
  }, [status]);

  const handleStatusChanged = (newStatus: UsedMaterialStatus) => {
    onStatusChanged(newStatus);
    setLastStatus(newStatus);

    const wasPaused = isAnimationPaused;

    if (
      (newStatus === UsedMaterialStatus.None || newStatus === UsedMaterialStatus.Prepared) &&
      (lastStatus === UsedMaterialStatus.None || lastStatus === UsedMaterialStatus.Prepared)
    ) {
      setIsAnimationPaused(false);

      if (wasPaused) {
        setLastStatusForLottie(lastStatus);
      } else {
        setTimeout(
          (() => {
            setIsAnimationPaused(false);
            setLastStatusForLottie(lastStatus);
          }) as TimerHandler,
          1000
        );
      }
    } else {
      setLastStatusForLottie(newStatus);
    }
  };

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (notEditable) {
      return;
    }

    if (lastStatus === UsedMaterialStatus.Prepared) {
      handleStatusChanged(UsedMaterialStatus.None);
    } else {
      handleStatusChanged(UsedMaterialStatus.Prepared);
    }
  };

  const onDropdownStatusChange = (s: UsedMaterialStatus) => {
    if (
      (s === UsedMaterialStatus.None || s === UsedMaterialStatus.Prepared) &&
      (lastStatus === UsedMaterialStatus.None || lastStatus === UsedMaterialStatus.Prepared)
    ) {
      setIsAnimationPaused(false);
      setTimeout(() => {
        handleStatusChanged(s);
      }, 1000);
    } else {
      handleStatusChanged(s);
    }
  };

  return (
    <>
      {(lastStatus === undefined || lastStatus === UsedMaterialStatus.Prepared || lastStatus === UsedMaterialStatus.None) &&
        (noCheck ? (
          <div className="status_only sub_list sub-list-check" />
        ) : (
          <div
            onClick={onClick}
            className={`btn_checkbox_in_matlist btn-checkbox-in-matlist ${notEditable ? 'not-editable' : ''}`}
            {...rest}
          >
            <Lottie
              ariaRole="button"
              options={defaultOptions}
              direction={1}
              speed={1.5}
              isPaused={isAnimationPaused}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => setIsAnimationPaused(true)
                }
              ]}
              isClickToPauseDisabled={notEditable}
            >
              <img src="images/check-white.svg" alt="" />
            </Lottie>
          </div>
        ))}

      <SurgeryMaterialCheckDropdown
        surgeryGuide={surgeryGuide}
        inSublist={inSublist}
        onStatusChanged={onDropdownStatusChange}
        status={lastStatus}
        usedMaterial={usedMaterial}
      />
    </>
  );
};

export default SurgeryMaterialCheckItem;
