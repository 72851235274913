import { AccountDTO, JwtPayloadDTO, UserDTO } from 'dto/user';
import { action, observable, runInAction } from 'mobx';
import { checkAuth } from 'api/auth';
import { setNewJwt, setOnUnauthorized } from 'api/common';
import defineAbilitiesFor from 'casl/setupCaslAbility';
import jwtDecode from 'jwt-decode';
import { getUser, refreshJwt, getAccount } from 'api/user';
import config from 'config/config';
import DomainStore from './domainStore';
import LoadingStore from './loadingStore';

export default class AuthStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  private domainStore: DomainStore;

  @observable
  public jwt = '';

  constructor(domainStore: DomainStore, loadingStore: LoadingStore) {
    setOnUnauthorized(() => this.logout());
    this.loadingStore = loadingStore;
    this.domainStore = domainStore;

    /**
     * refreshes jwt every 8 minutes
     */
    setInterval(async () => {
      if (!this.isLoggedIn) {
        return;
      }
      if (!this.jwt) {
        return;
      }

      const newJwt: string = await refreshJwt();
      this.setJwt(newJwt);
      // also sets permissions(abilities) for a user so if an admin changes user permissions they are updated as well
      await this.setAbilitiesForUser();
    }, config.jwt?.refreshInMilliSeconds || 360000);
  }

  @observable
  user!: UserDTO;

  @observable
  isLoggedIn = false;

  /**
   * call this function when user is logging in
   *
   * @param user username for login
   * @param password password for login
   */
  async checkLogin(user: string, password: string) {
    return this.loadingStore
      .withLoadingBar(() => checkAuth(user, password))
      .then(result => {
        this.setJwt(result.token);

        /**
         * if config.devMode is set to true the token is saved inside the localStorage so when you refresh the page you dont have to log in again
         */
        if (config.devMode) {
          this.setDataToStorage(result.token);
        }
        return this.login(result.account).then(() => {
          if (result) {
            return true;
          }
          return false;
        });
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  }

  /**
   * function to set the User that is currently logged in
   *
   * @param account account details of the currently logged in user...
   */
  @action
  login = async (account: AccountDTO) => {
    const user = await getUser();

    await this.setAbilitiesForUser();
    this.domainStore.setCurrentUnits(account);

    await this.domainStore.loadAllDepartments();

    runInAction(() => {
      this.user = user;
      this.isLoggedIn = true;
    });
  };

  /**
   * sets the permissions for a user for casl
   */
  @action
  setAbilitiesForUser = async () => {
    const jwtPayload: JwtPayloadDTO = jwtDecode(this.jwt);

    this.domainStore.ability = defineAbilitiesFor(jwtPayload);
  };

  /**
   * save passed jwt to localStorage devMode use only
   * @param token jwt
   */
  @action
  setDataToStorage = (token: string) => {
    localStorage.setItem('auth', token);
  };

  @action
  clearStorageData = () => {
    localStorage.removeItem('auth');
  };

  /**
   * used when reloading page
   * gets the token out of the localStorage for devMode use only
   */
  @action
  async autoDevelopLogin() {
    if (!config.devMode || this.isLoggedIn) {
      return false;
    }

    const currentJwt = localStorage.getItem('auth');
    if (!currentJwt) {
      return false;
    }

    this.setJwt(currentJwt);
    return getAccount()
      .then(account => {
        this.login(account);
        return true;
      })
      .catch(() => {
        this.logout();
        return false;
      });
  }

  /**
   * function to log out currently logged in user
   */
  @action
  async logout() {
    this.clearStorageData();

    if (!this.isLoggedIn) {
      return;
    }
    this.setJwt('');
    this.isLoggedIn = false;
  }

  /**
   * sets a jwt inside authStore and in the api/common.ts file as a variable (for api use)
   *
   * @param jwt jwt to set to store and common api variable
   */
  @action
  setJwt(jwt: string) {
    this.jwt = jwt;
    setNewJwt(jwt);
  }
}
