/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, IsOptional, ValidateNested, IsIn, IsDate, ValidateIf, IsEnum, IsNotEmptyObject, IsInt } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { SimpleDepartmentDTO } from './department';
import { FullGuideDTO } from './guide';
import { UserDTO } from './user';
import { PagingDTO } from './generic';
import { TemplateDTO } from './template';
import { SimplePackageDTO, PackageDTO } from './package';
import { OptionalPictureDTO } from './file';
import { BasicMaterialSetDTO, SimpleMaterialSetDTO, MaterialSetDTO } from './materialSet';

/**
 * MaterialType tells only the most basic difference. It does not tell you if it is in any set for example.
 * But for example as instruments should never appear as normal material it can be either material or instrument.
 */
export enum MaterialType {
  material,
  instrument
}

export class MaterialDTO extends OptionalPictureDTO {
  @IsUUID()
  materialId!: string;

  @IsString()
  name!: string;

  /**
   * user is not always included. Use userId to check if the material has a user.
   */
  @IsUUID()
  @IsOptional()
  userId?: string;

  /**
   * user is not always included. Use userId to check if the material has a user.
   */
  @IsOptional()
  user?: UserDTO;

  @IsString()
  @IsOptional()
  manufacturerArticleNumber?: string;

  @IsUUID()
  @IsOptional()
  departmentId?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsOptional()
  price?: string;

  @IsString()
  @IsOptional()
  packageSize?: string;

  @IsString()
  @IsOptional()
  manufacturerName?: string;

  @IsString()
  @IsOptional()
  supplier?: string;

  @IsString()
  @IsOptional()
  erpId?: string;

  @IsString()
  @IsOptional()
  lastSynchronisation?: string;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  createdAt?: Date;

  /**
   * packages contains all packages the material is in.
   * NOTE: it is not always mapped, even if the material is in a package
   */
  @ValidateNested({ each: true })
  @IsOptional()
  @Type(() => SimplePackageDTO)
  packages?: SimplePackageDTO[];

  @IsEnum(MaterialType)
  @IsOptional()
  materialType?: MaterialType;
}

/**
 * MaterialLikeIdDTO contains any id of material-like entities (e.g. material, template, materialSet)
 * But only one at a time.
 */
export class MaterialLikeIdDTO {
  // Important: also update the assertOnlyOneMaterialLikeId function below if you add another id
  @ValidateIf(dto => dto.templateId === undefined && dto.materialSetId === undefined)
  @IsUUID()
  materialId?: string;

  @ValidateIf(dto => dto.materialId === undefined && dto.materialSetId === undefined)
  @IsUUID()
  templateId?: string;

  @ValidateIf(dto => dto.materialId === undefined && dto.templateId === undefined)
  @IsUUID()
  materialSetId?: string;
}

export class UsageCountDTO {
  @IsInt()
  count!: number;
}

// MaterialLikeIdOrPackageIdDTO is similar to MaterialLikeIdDTO but instead of the template it contains the package.
export class MaterialLikeIdOrPackageIdDTO {
  // Important: also update the assertOnlyOneMaterialLikeIdOrPackageId function below if you add another id
  @ValidateIf(dto => dto.packageId === undefined && dto.materialSetId === undefined)
  @IsUUID()
  materialId?: string;

  @ValidateIf(dto => dto.materialId === undefined && dto.materialSetId === undefined)
  @IsUUID()
  packageId?: string;

  @ValidateIf(dto => dto.materialId === undefined && dto.packageId === undefined)
  @IsUUID()
  materialSetId?: string;
}

export const assertOnlyOneMaterialLikeId = (ids: MaterialLikeIdDTO) => {
  if ([ids.materialId, ids.materialSetId, ids.templateId].filter(e => e !== undefined).length !== 1) {
    throw new Error('exactly one material-like id has to be provided');
  }
};

/**
 * MaterialLikeDTO contains any of material-like entities (e.g. material, template, materialSet)
 * But only one at a time.
 */
export class MaterialLikeDTO {
  @ValidateIf(dto => dto.template === undefined && dto.materialSet === undefined)
  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => MaterialDTO)
  material?: MaterialDTO;

  @ValidateIf(dto => dto.material === undefined && dto.materialSet === undefined)
  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => TemplateDTO)
  template?: TemplateDTO;

  @ValidateIf(dto => dto.material === undefined && dto.template === undefined)
  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => MaterialSetDTO)
  materialSet?: MaterialSetDTO;
}

/**
 * SimpleMaterialLikeDTO contains any of material-like entities (e.g. material, template, materialSet)
 * But only one at a time.
 * It includes them only as simple entities -> it does not contain the packages / set groups
 */
export class SimpleMaterialLikeDTO {
  @ValidateIf(dto => dto.template === undefined && dto.materialSet === undefined)
  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => MaterialDTO)
  material?: MaterialDTO;

  @ValidateIf(dto => dto.material === undefined && dto.materialSet === undefined)
  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => TemplateDTO)
  template?: TemplateDTO;

  @ValidateIf(dto => dto.material === undefined && dto.template === undefined)
  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => BasicMaterialSetDTO)
  materialSet?: BasicMaterialSetDTO;
}

export const assertOnlyOneMaterialLikeIdOrPackageId = (ids: MaterialLikeIdOrPackageIdDTO) => {
  if ([ids.materialId, ids.materialSetId, ids.packageId].filter(e => e !== undefined).length !== 1) {
    throw new Error('exactly one material-like id has to be provided');
  }
};

export type FilterBy = 'all' | 'manual' | 'instruments' | 'erp' | 'implants' | 'sets' | 'medicals' | 'rentalMaterials';

/**
 * MaterialSearchDTO contains one search-in-all-field (query) and additional filter-fields
 */
export class MaterialSearchDTO extends PagingDTO {
  @IsString()
  @IsOptional()
  query?: string;

  @IsUUID()
  @IsOptional()
  locationId?: string;

  @IsUUID()
  @IsOptional()
  departmentId?: string;

  @IsString()
  @IsIn(['createdAt'])
  @IsOptional()
  sortBy?: 'createdAt';

  @IsString()
  @IsIn(['asc', 'desc'])
  @IsOptional()
  sortDirection?: 'asc' | 'desc';

  @IsString()
  @IsIn(['all', 'manual', 'instruments', 'erp', 'implants', 'sets', 'medicals', 'rentalMaterials'])
  filterBy!: FilterBy;
}

export class CreateMaterialDTO {
  @IsString()
  name!: string;

  @IsString()
  manufacturerArticleNumber!: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class SetImportMaterialDTO {
  @IsString()
  name!: string;

  @IsString()
  manufacturerArticleNumber?: string;

  @IsString()
  externalId!: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;

  @IsString()
  @IsOptional()
  erpId?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsOptional()
  price?: string;
}

export class UpdateMaterialDTO {
  @IsUUID()
  materialId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsUUID()
  @IsOptional()
  userId?: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;

  @IsString()
  @IsOptional()
  manufacturerArticleNumber?: string;

  @IsUUID()
  @IsOptional()
  departmentId?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsOptional()
  price?: string;

  @IsString()
  @IsOptional()
  packageSize?: string;

  @IsString()
  @IsOptional()
  manufacturerName?: string;

  @IsString()
  @IsOptional()
  supplier?: string;

  @IsString()
  @IsOptional()
  erpId?: string;
}

export class MaterialIdDTO {
  @IsUUID()
  materialId!: string;
}

export class GuideMaterialIdDTO {
  @IsUUID()
  guideMaterialId!: string;
}

export class GroupedMaterialGuidesDTO {
  @ValidateNested()
  @Type(() => SimpleDepartmentDTO)
  department!: SimpleDepartmentDTO;

  @ValidateNested({ each: true })
  @Type(() => FullGuideDTO)
  guides!: FullGuideDTO[];
}

export class MaterialSearchResultDTO {
  @ValidateNested()
  @IsOptional()
  @Type(() => MaterialDTO)
  material?: MaterialDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => PackageDTO)
  package?: PackageDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => TemplateDTO)
  template?: TemplateDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => SimpleMaterialSetDTO)
  materialSet?: SimpleMaterialSetDTO;
}

/**
 * MaterialContainersDTO contains all associations that use a Material, like Instruments and GroupMaterials.
 */
export class MaterialContainersDTO extends OptionalPictureDTO {
  @IsUUID()
  materialId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  manufacturerArticleNumber?: string;

  @IsString()
  @IsOptional()
  erpId?: string;

  @IsEnum(MaterialType)
  materialType!: MaterialType;

  @ValidateNested()
  @IsOptional()
  @Type(() => SimplePackageDTO)
  packages?: SimplePackageDTO[];

  @ValidateNested()
  @IsOptional()
  @Type(() => BasicMaterialSetDTO)
  materialSets?: BasicMaterialSetDTO[];
}
