import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

import CustomInput from 'components/CustomInput';
import FlyoutContainer from 'components/FlyoutContainer';
import { SetUsedMaterialDTO } from 'dto/usedMaterial';

const EditLotNumberFlyout = observer(() => {
  const { t } = useTranslation('briefing');
  const { surgeryGuideStore } = useStores();
  const [lot, setLot] = useState(surgeryGuideStore.selectedUsedMaterialToEdit?.lotNumber);
  const closeFlyout = () => surgeryGuideStore.setIsEditLotNumberFlyoutOpen(false);

  useEffect(() => {
    if (surgeryGuideStore.selectedUsedMaterialToEdit?.lotNumber) {
      setLot(surgeryGuideStore.selectedUsedMaterialToEdit.lotNumber);
    } else {
      setLot(undefined);
    }
  }, [surgeryGuideStore.selectedUsedMaterialToEdit]);

  const onSubmit = async () => {
    let dataToUpdate: SetUsedMaterialDTO | undefined;
    if (surgeryGuideStore.selectedSurgeryGuideMaterialToEdit) {
      dataToUpdate = {
        surgeryGuideMaterialId: surgeryGuideStore.selectedSurgeryGuideMaterialToEdit.surgeryGuideMaterialId,
        lotNumber: lot
      };
    }
    if (surgeryGuideStore.selectedUsedMaterialToEdit?.groupMaterialId && dataToUpdate) {
      dataToUpdate.groupMaterialId = surgeryGuideStore.selectedUsedMaterialToEdit.groupMaterialId;
    }
    if (surgeryGuideStore.selectedUsedMaterialToEdit?.packageId && dataToUpdate) {
      dataToUpdate.packageId = surgeryGuideStore.selectedUsedMaterialToEdit.packageId;
    }
    if (dataToUpdate) {
      await surgeryGuideStore.setUsedMaterial(dataToUpdate);
    }
    closeFlyout();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_tag.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={surgeryGuideStore.isEditLotNumberFlyoutOpen}
      closePopUp={closeFlyout}
      cancelLabel={t('briefingMaterials.editLotNumberFlyout.button.cancel')}
      onSubmit={onSubmit}
      submitLabel={t('briefingMaterials.editLotNumberFlyout.button.submit')}
      isAllowedToSubmit={lot !== ''}
      title={t('briefingMaterials.editLotNumberFlyout.headline')}
    >
      <div className="flyout_box">
        <div>{t('briefingMaterials.editLotNumberFlyout.label.lotNumberName')}</div>
        <CustomInput
          value={lot}
          onChange={event => setLot(event.target.value)}
          placeholder={t('briefingMaterials.editLotNumberFlyout.label.lotNumberPlaceholder')}
        />
      </div>
    </FlyoutContainer>
  );
});

export default EditLotNumberFlyout;
