import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import ArrowIcon from 'components/ArrowIcon';
import ImageCircle from 'components/ImageCircle';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import Popover from 'components/Popover';
import './BookListItemBody.css';
import { Can, subjectArea, actions, AbilityContext } from 'casl/setupCaslAbility';
import { GuideDTO } from 'dto/guide';
import ValueTag from 'components/ValueTag';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';

interface Props {
  guide: GuideDTO;
  hover?: boolean;
}

const BookListItemBody = observer((props: Props) => {
  const { guideStore } = useStores();
  const { t } = useTranslation('guide');
  const ability = useAbility(AbilityContext);

  /**
   * wrapper for the button clicks which closes the dialog and stops the click event
   * @param event
   * @param handler
   */
  const menuButtonClickHandlerWrapper = (event: React.MouseEvent, handler: (event: React.MouseEvent) => void) => {
    handler(event);
    event.preventDefault();
  };

  const handlerCopyGuide = (event: React.MouseEvent, handler: (event: React.MouseEvent) => void) => {
    handler(event);
    event.preventDefault();
  };

  // if the user has any of these rights he should see the 3 dot menu instead of the arrow
  const isAllowedToSee3DotMenu =
    ability.can(actions.add, subject(subjectArea.guide, { departmentId: props.guide.departmentId })) ||
    ability.can(actions.remove, subject(subjectArea.guide, { departmentId: props.guide.departmentId })) ||
    ability.can(actions.update, subject(subjectArea.guide, { departmentId: props.guide.departmentId }));

  return (
    <>
      <ImageCircle builtinPictureName="icon_book" highlight={!!props.hover} />
      <div className="book_info book_hover_blue">
        <div>{props.guide.name}</div>
        <div className="btn_show_detail btn-detail-position">
          <ValueTag title={t('guideId')} value={props.guide.guideNumber.toString()} />
          {!guideStore.guideMaterialsToClone && !guideStore.guideProcedureToClone && isAllowedToSee3DotMenu && (
            <Popover trigger={<ArrowDotsVerticalIcon direction={!props.hover} />}>
              {({ handleClose, isOpen }) => (
                <>
                  <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
                    <Can I={actions.add} this={subject(subjectArea.guide, { departmentId: props.guide.departmentId })}>
                      <ItemMenuButton
                        label={t('button.duplicateGuide')}
                        icon="icon_single-copy-04_16.svg"
                        isInPopover
                        handleClick={event =>
                          handlerCopyGuide(event, () => {
                            guideStore.cloneGuide({ sourceGuideId: props.guide.guideId });
                            handleClose();
                          })
                        }
                      />
                    </Can>
                    <Can I={actions.update} this={subject(subjectArea.guide, { departmentId: props.guide.departmentId })}>
                      <ItemMenuButton
                        label={t('button.renameGuide')}
                        icon="icon_background_16.svg"
                        isInPopover
                        handleClick={event =>
                          menuButtonClickHandlerWrapper(event, () => {
                            guideStore.setSelectedGuide(props.guide);
                            guideStore.setShowGuideFlyout(true);
                            handleClose();
                          })
                        }
                      />
                    </Can>

                    <Can I={actions.remove} this={subject(subjectArea.guide, { departmentId: props.guide.departmentId })}>
                      <ItemMenuButton
                        label={t('button.deleteGuide')}
                        icon="icon_bin_16.svg"
                        warning
                        isInPopover
                        handleClick={event =>
                          menuButtonClickHandlerWrapper(event, () => {
                            guideStore.setSelectedGuide(props.guide);
                            guideStore.showDeleteGuideFlyout(props.guide.guideId);
                            handleClose();
                          })
                        }
                      />
                    </Can>
                  </ItemMenu>
                </>
              )}
            </Popover>
          )}

          {(!!guideStore.guideMaterialsToClone || !!guideStore.guideProcedureToClone || !isAllowedToSee3DotMenu) && (
            <ArrowIcon hover={props.hover} />
          )}
        </div>
      </div>
    </>
  );
});

export default BookListItemBody;
