/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { reorderSetGroups } from 'api/setGroup';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { EditableSetGroupListItem } from 'components/ListItems';
import Button from 'components/Form/Button';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import MaterialSetGroupsRightMenu from './MaterialSetGroupsRightMenu';
import CheckListAuthorBox from './CheckListAuthorBox';
import './MaterialSetGroups.css';
import MaterialSetGroupsRightMenuMobile from './MaterialSetGroupsRightMenuMobile';

interface Props {
  groupType: string;
  checkListDisabled: boolean;
}

const MaterialSetGroups = observer(({ groupType, checkListDisabled }: Props) => {
  const { materialSetsStore, appNavigationStore, domainStore } = useStores();
  const [setGroups, setSetGroups] = useState(materialSetsStore.materialSetDetail?.setGroups);
  // Hide until the checks are reset to prevent showing them for a short time.
  const [show, setShow] = useState(false);
  const { t } = useTranslation(groupType);
  // set the component for the right menu
  useEffect(() => {
    if (!materialSetsStore.isCheckListVisible && !domainStore.isMobile) {
      appNavigationStore.setRightMenuBuilder(() => {
        return <MaterialSetGroupsRightMenu groupType={groupType} checkListDisabled={checkListDisabled} />;
      });
      return;
    }
    if (!materialSetsStore.isCheckListVisible && !checkListDisabled && domainStore.isMobile) {
      appNavigationStore.setRightMenuBuilder(() => {
        return <MaterialSetGroupsRightMenuMobile groupType={groupType} checkListDisabled={checkListDisabled} />;
      });
      return;
    }
    appNavigationStore.withoutMenu();
  }, [appNavigationStore, groupType, checkListDisabled, domainStore.isMobile, materialSetsStore.isCheckListVisible]);

  useEffect(() => {
    if (materialSetsStore.isCheckListVisible) {
      materialSetsStore.loadMaterialSetChecks(materialSetsStore.materialSetId);
      if (!materialSetsStore.selectedSetCheck) {
        materialSetsStore.generateCheckItems();
      }
    }
    if (materialSetsStore.materialSetDetail) {
      setSetGroups(materialSetsStore.materialSetDetail.setGroups);
    }
  }, [materialSetsStore, materialSetsStore.isCheckListVisible, materialSetsStore.materialSetDetail]);

  useEffect(() => {
    materialSetsStore.setIsCheckListVisible(false);
    setShow(true);
  }, [materialSetsStore]);

  const handleCreateGroup = () => {
    materialSetsStore.setIsSetGroupFormFlyoutOpen(true);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !setGroups) return;
    const setGroupToBeRemoved = setGroups.find(group => group.setGroupId === result.draggableId);
    const oldSetGroups = setGroups.filter(group => group.setGroupId !== result.draggableId);
    if (setGroupToBeRemoved) {
      oldSetGroups.splice(result.destination.index, 0, setGroupToBeRemoved);
      setSetGroups([...oldSetGroups]);
      reorderSetGroups({
        setGroupIds: oldSetGroups.map(group => group.setGroupId)
      });
    }
  };

  appNavigationStore.useSubPageIdSetter('list');

  if (setGroups && show) {
    return (
      <>
        {!materialSetsStore.isCheckListVisible && !checkListDisabled && (
          <div className="mobile_only_container">
            <Button className="btn_start_control" onClick={() => materialSetsStore.setIsCheckListVisible(true)}>
              <img src="images/icon_checkbox_check_16.svg" alt="" className="image_pack_done" />
              {t('groupsList.button.startImplantCheck')}
            </Button>
          </div>
        )}
        <div className={`div-block-102_fixed set-group-left-line ${materialSetsStore.isCheckListVisible ? 'visible' : ''}`} />
        <div className={`div-block-102_white set-group-left-white-area ${materialSetsStore.isCheckListVisible ? 'visible' : ''}`} />
        <div className="single_colum_content flex_width">
          {materialSetsStore.isCheckListVisible && <CheckListAuthorBox groupType={groupType} />}
          {!materialSetsStore.isCheckListVisible && (
            <Can I={actions.add} this={subject(subjectArea.materialSetGroup, { departmentId: domainStore.currentDepartment.id })}>
              <div>
                <ListItemAdd className="list_item_material_add in_packlist" onClick={handleCreateGroup}>
                  <InfoBox label={t('groupsList.button.createGroup')} />
                </ListItemAdd>
              </div>
            </Can>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={materialSetsStore.materialSetId} type="setGroup">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} className={`single-colum-content-item ${snapshot.isDraggingOver ? 'no-dropdown' : ''}`}>
                  {setGroups &&
                    setGroups.map((group, index) => (
                      <EditableSetGroupListItem setGroup={group} index={index} key={group.setGroupId} groupType={groupType} />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  }
  return null;
});

export default MaterialSetGroups;
