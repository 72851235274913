import React from 'react';
import { observer } from 'mobx-react';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryMaterialItem from './SurgeryMaterialItem';
import SurgeryMaterialSetItem from './SurgeryMaterialSetItem';
import SurgeryTemplateListItem from './SurgeryTemplateListItem';

interface Props extends OptionalLazyLoadProps {
  materialLike: SurgeryGuideMaterialLikeDTO;
  dropdownContainerIndex: number;
}

const SurgeryMaterialListItem = observer(({ materialLike, dropdownContainerIndex, lazyLoadScrollContainer }: Props) => {
  if (materialLike.material) {
    return (
      <SurgeryMaterialItem
        key={materialLike.surgeryGuideMaterialId}
        material={materialLike.material}
        surgeryGuide={materialLike}
        dropdownContainerIndex={dropdownContainerIndex}
        lazyLoadScrollContainer={lazyLoadScrollContainer}
      />
    );
  }
  if (materialLike.materialSet) {
    return (
      <SurgeryMaterialSetItem
        key={materialLike.surgeryGuideMaterialId}
        materialSet={materialLike.materialSet}
        surgeryGuide={materialLike}
        dropdownContainerIndex={dropdownContainerIndex}
        lazyLoadScrollContainer={lazyLoadScrollContainer}
      />
    );
  }
  if (materialLike.template) {
    return (
      <SurgeryTemplateListItem
        key={materialLike.surgeryGuideMaterialId}
        template={materialLike.template}
        surgeryGuide={materialLike}
        dropdownContainerIndex={dropdownContainerIndex}
        lazyLoadScrollContainer={lazyLoadScrollContainer}
      />
    );
  }
  return null;
});

export default SurgeryMaterialListItem;
