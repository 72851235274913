import React from 'react';
import { observer } from 'mobx-react';
import { MaterialDTO } from 'dto/material';
import { useStores } from 'util/mobx/stores';

import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Picture from 'components/Picture';
import Popover from 'components/Popover';
import HoverWrapper from 'components/HoverWrapper';
import MaterialValueTags from 'components/MaterialValueTags';
import Instructions from 'components/ListItems/components/Instructions';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryMaterialItemMenu from './SurgeryMaterialItemMenu';
import SurgeryMaterialCheckItem from '../SurgeryMaterialCheckItem';

import './SurgeryMaterialItem.css';

interface Props extends OptionalLazyLoadProps {
  material: MaterialDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  dropdownContainerIndex: number;
}

const SurgeryMaterialItem = observer(({ material, surgeryGuide, dropdownContainerIndex, lazyLoadScrollContainer }: Props) => {
  const { guideStore, materialStore, guideDetailDrawerStore, surgeryGuideStore } = useStores();

  const handleClick = () => {
    guideDetailDrawerStore.setSelectedMaterial(material);
    guideDetailDrawerStore.setSelectedSurgeryGuideItem(surgeryGuide);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const onUpdateFile = async (fileId: string) => {
    await materialStore.updateMaterial({ materialId: material.materialId, pictureFileId: fileId });
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideStore.surgeryGuide.surgeryGuideId);
    }
  };

  const usedMaterial = surgeryGuide.usedMaterials.find(m => m.surgeryGuideMaterialId === surgeryGuide.surgeryGuideMaterialId);

  const onStatusChange = (status: UsedMaterialStatus) => {
    surgeryGuideStore.setUsedMaterial({
      surgeryGuideMaterialId: surgeryGuide.surgeryGuideMaterialId,
      status
    });
  };

  return (
    <HoverWrapper className="list_item_material surgery-material" style={{ zIndex: dropdownContainerIndex }}>
      {({ hover }) => (
        <>
          <div className="item_count _1_lvl">
            <div>{surgeryGuide.amount}</div>
          </div>
          <div className="item_dropdown_holder">
            <img src="images/icon_s-edit_17.svg" width={16} alt="" className="image_arrow_dropdown hidden" />
          </div>
          <div className="image_wrapper_50">
            <SurgeryMaterialCheckItem
              onStatusChanged={onStatusChange}
              status={usedMaterial?.status}
              surgeryGuide={surgeryGuide}
              usedMaterial={usedMaterial}
            />
            {!surgeryGuide.storageLocationId && (
              <div className="icon_no_location">
                <img src="images/icon_orange_flag.jpg" alt="" />
              </div>
            )}
            {material.picture ? (
              <>
                <Picture
                  withBorder
                  src={material}
                  size="pictureThumbnail"
                  alt={material.name}
                  lazyLoadScrollContainer={lazyLoadScrollContainer}
                  className="image_circle_50"
                />
              </>
            ) : (
              <GetCameraImageButton
                buttonStyle="icon"
                onUpdateFile={onUpdateFile}
                description=""
                picturesOnly
                isAddable={!guideStore.selectedGuideCloneFlyout && true}
                hover={hover}
                withLoadingBar
              />
            )}
          </div>
          <div className="material_info">
            <div className="material_text" onClick={() => handleClick()}>
              <div>{material.name}</div>
              <Instructions notes={surgeryGuide.notes} />
            </div>
            <MaterialValueTags material={material} onClick={() => handleClick()} />
            <div className="btn_show_detail btn-detail-position">
              <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                {({ handleClose, isOpen }) => (
                  <SurgeryMaterialItemMenu
                    isOpen={!!isOpen}
                    handleClose={handleClose}
                    surgeryGuide={surgeryGuide}
                    usedMaterial={usedMaterial}
                    onOpenDetails={handleClick}
                  />
                )}
              </Popover>
            </div>
          </div>
        </>
      )}
    </HoverWrapper>
  );
});

export default SurgeryMaterialItem;
