import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { SetGroupDTO } from 'dto/setGroup';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import Picture from 'components/Picture';
import HoverWrapper from 'components/HoverWrapper';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Tags from 'components/ListItems/components/Tags';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import { useStores } from 'util/mobx/stores';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryGroupMaterialListItem from '../SurgeryGroupMaterialListItem';

import './SurgerySetGroupListItem.css';

interface Props extends OptionalLazyLoadProps {
  setGroup: SetGroupDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  dropdownContainerIndex: number;
}

const SurgerySetGroupListItem = observer(({ setGroup, surgeryGuide, dropdownContainerIndex, lazyLoadScrollContainer }: Props) => {
  const [groupMaterials, setGroupMaterials] = useState(setGroup.groupMaterials);
  const { materialSetsStore, surgeryGuideStore } = useStores();

  useEffect(() => {
    setGroupMaterials(setGroup.groupMaterials);
  }, [setGroup.groupMaterials]);

  const renderList = () => (
    <div className="content_holder no_padding padding_left instruments-list">
      {groupMaterials.map((groupMaterial, index) => {
        return (
          <SurgeryGroupMaterialListItem
            lazyLoadScrollContainer={lazyLoadScrollContainer}
            zIndex={dropdownContainerIndex - index}
            groupMaterial={groupMaterial}
            key={`${groupMaterial.groupMaterialId}${groupMaterial.material.pictureThumbnail}`}
            surgeryGuide={surgeryGuide}
          />
        );
      })}
    </div>
  );

  const onUpdateFile = async (file: string) => {
    await materialSetsStore.updateSetGroup({ setGroupId: setGroup.setGroupId, pictureFileId: file });
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideStore.surgeryGuide.surgeryGuideId);
    }
  };

  return (
    <ListItemDropdownContainer
      classPrefix="set-group"
      className="surgery-set-group"
      ListElement={renderList}
      key={setGroup.setGroupId}
      style={{
        zIndex: dropdownContainerIndex
      }}
    >
      <HoverWrapper className="list_item_material">
        {() => (
          <>
            <div className="image_wrapper_50">
              {setGroup.picture ? (
                <Picture
                  withBorder
                  src={setGroup}
                  size="pictureThumbnail"
                  width={50}
                  alt=""
                  lazyLoadScrollContainer={lazyLoadScrollContainer}
                  className="image_circle_50"
                />
              ) : (
                <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable withLoadingBar />
              )}
            </div>
            <div className="material_info w-inline-block package-info">
              <div className="material_text material-text">
                <div className="link-block">{setGroup.name}</div>
                <Tags tags={setGroup.tags} />
              </div>
              <MaterialValueTags setGroup={setGroup} fullInfo />
            </div>
          </>
        )}
      </HoverWrapper>
    </ListItemDropdownContainer>
  );
});

export default SurgerySetGroupListItem;
