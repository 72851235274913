import React, { ChangeEvent, useEffect } from 'react';
import { saveFileContentElement } from 'api/file';
import { FormikProps, FormikValues } from 'formik';
import { getFileName, getMediaType, getFileExtension } from 'util/fileUtils';
import ThumbnailList from 'components/ThumbnailList';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/UploadMultipleFiles';
import ImageCircle from 'components/ImageCircle';
import { useStores } from 'util/mobx/stores';
import { getAnimationState } from 'util/animation';
import './BoardInput.css';
import { ProcessingStatus } from 'dto/file';

interface Props extends React.HTMLProps<HTMLDivElement> {
  placeholder: string;
  meta: FormikProps<FormikValues>;
  name: string;
  isExpanded: boolean | null;
  onExpand: () => void;
  uploadedFiles: MixedContentElementMediaDTO[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<MixedContentElementMediaDTO[]>>;
}

const BoardInput = ({ placeholder, name, meta, isExpanded, onExpand, setUploadedFiles, uploadedFiles, ...rest }: Props) => {
  const { authStore } = useStores();

  const gotImage = (fakeId: string, file: MixedContentElementMediaDTO) => {
    meta.setFieldValue('contentElementMedias', [...meta.values.contentElementMedias, file]);
    setUploadedFiles(current => [
      ...current.map(oldFile => {
        if (oldFile.fileId === fakeId) {
          return file;
        }
        return oldFile;
      })
    ]);
  };

  useEffect(() => {
    setUploadedFiles(meta.values.contentElementMedias);
  }, [meta, setUploadedFiles]);

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (name) {
      meta.setFieldValue(name, event.target.value);
    }
  };

  const onFilesChange = (updatedFiles: MixedContentElementMediaDTO[]) => {
    meta.setFieldValue('contentElementMedias', [...updatedFiles]);
  };

  const onSaveFile = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const progressFileId = (file.lastModified + Math.random() * 100).toString();
    const response = await saveFileContentElement(bodyFormData, e => {
      setUploadedFiles(current => {
        const extension = getFileExtension(file.name);
        const fileInProgress = current.find(f => f.fileId === progressFileId);

        if (fileInProgress) {
          const updatedFiles = current.map(f => {
            if (f.fileId === progressFileId) {
              return {
                ...f,
                progress: Math.round((e.loaded / e.total) * 100)
              };
            }
            return f;
          });
          return [...updatedFiles];
        }
        const newFile: MixedContentElementMediaDTO = {
          ...file,
          contentType: getMediaType(extension),
          progress: Math.round((e.loaded / e.total) * 100),
          fileId: progressFileId,
          status: ProcessingStatus.Uploading,
          fullUrl: '',
          originalFileName: ''
        };
        return [...current, newFile];
      });
    });

    const title = getFileName(file.name, file.type);
    if (response) {
      gotImage(progressFileId, {
        ...response,
        title
      });
    }
  };

  return (
    <div className={`b_chat_input-copy b-chat-input ${getAnimationState(isExpanded, 'expanded', 'collapsed')}`} {...rest}>
      <div className="field_text with_media field-text" onClick={() => onExpand()}>
        <ImageCircle
          user={authStore.user}
          highlight={false}
          size="40"
          className={`board-input-image ${getAnimationState(isExpanded, 'expanded', 'collapsed')}`}
        />
        <div className={`text-block-14 with_input board-input-text ${getAnimationState(isExpanded, 'expanded', 'collapsed')}`}>
          <textarea onChange={onCommentChange} value={meta.values[name]} placeholder={placeholder} name={name} autoComplete="off" />
          <ThumbnailList saveFile={saveFileContentElement} onChange={onFilesChange} files={uploadedFiles} inComment />
        </div>
      </div>
      <GetCameraImageButton className="btn_add_media_dialog" buttonStyle="board" onSaveFile={onSaveFile} isAddable />
    </div>
  );
};

export default BoardInput;
