import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { TemplateDTO } from 'dto/template';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import Instructions from 'components/ListItems/components/Instructions';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import { PackagingType } from 'dto/package';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryPackageListItem from '../SurgeryPackageListItem';
import SurgerySingleInstrumentListItem from '../SurgerySingleInstrumentListItem';

import './SurgeryTemplateListItem.css';

interface Props extends OptionalLazyLoadProps {
  template: TemplateDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  dropdownContainerIndex: number;
}

const SurgeryTemplateListItem = observer(({ template, surgeryGuide, dropdownContainerIndex, lazyLoadScrollContainer }: Props) => {
  const [checkedPackages, setCheckedPackages] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const result: Record<string, boolean> = {};

    surgeryGuide.usedMaterials.forEach(m => {
      if (!m.packageId) {
        return;
      }

      result[m.packageId] = m.status !== UsedMaterialStatus.None;
    });

    setCheckedPackages(result);
  }, [surgeryGuide]);

  const amountOfCheckedPackages = useMemo(() => {
    return Object.values(checkedPackages).filter(v => v === true).length;
  }, [checkedPackages]);

  function addOneCheck(packageId: string) {
    setCheckedPackages(current => {
      return {
        ...current,
        [packageId]: true
      };
    });
  }

  function removeOneCheck(packageId: string) {
    setCheckedPackages(current => {
      return {
        ...current,
        [packageId]: false
      };
    });
  }

  const renderList = () => {
    return (
      <div
        className="content_holder no_padding padding_left in_matlist lvl_1 guide-template-instruments-list"
        style={{ zIndex: dropdownContainerIndex }}
      >
        {template.packages.map((packageItem, index) => {
          if (packageItem.packagingType === PackagingType.Single) {
            return (
              <SurgerySingleInstrumentListItem
                lazyLoadScrollContainer={lazyLoadScrollContainer}
                onAddOneCheck={addOneCheck}
                onRemoveOneCheck={removeOneCheck}
                key={packageItem.packageId}
                singleInstrument={packageItem}
                surgeryGuide={surgeryGuide}
              />
            );
          }
          return (
            <SurgeryPackageListItem
              lazyLoadScrollContainer={lazyLoadScrollContainer}
              onAddOneCheck={addOneCheck}
              onRemoveOneCheck={removeOneCheck}
              packageItem={packageItem}
              surgeryGuide={surgeryGuide}
              key={packageItem.packageId}
              dropdownContainerIndex={dropdownContainerIndex - (index + 1)}
            />
          );
        })}
        <div className="div-block-102_fixed-copy">
          <div className="div-block-108-copy" />
        </div>
      </div>
    );
  };

  return (
    <ListItemDropdownContainer
      ListElement={renderList}
      classPrefix="template"
      className="surgery-template"
      inGuide
      style={{ zIndex: dropdownContainerIndex }}
    >
      <div className="item_count _1_lvl ">
        <div>{surgeryGuide.amount}</div>
      </div>
      <div className="item_dropdown_holder" />
      <div className="image_wrapper_50">
        {amountOfCheckedPackages >= surgeryGuide.amount ? (
          <div className="status_only in_mainlist status-only checked-status">
            <img src="images/checked-icon.svg" alt="" />
          </div>
        ) : (
          <div className="status_only in_mainlist status-only" />
        )}

        <div className="image_border white image-border" />
        <img src="images/icon_layer.jpg" alt="" className="image_circle_50" />
      </div>
      <div className="material_info">
        <div className="material_text">
          <div>{template.name}</div>
          <Instructions notes={surgeryGuide.notes} />
        </div>
      </div>
    </ListItemDropdownContainer>
  );
});

export default SurgeryTemplateListItem;
