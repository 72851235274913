import React from 'react';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useTranslation } from 'react-i18next';

import './UserSettingsNavigation.css';
import { version } from 'constants/version';

interface Props {
  currentPath: string;
}

const UserSettingsNavigation = observer(({ currentPath }: Props) => {
  const { t } = useTranslation('accountManagement');
  return (
    <>
      <NavSectionButton icon="icon_globe_16" label={t('subMenu.users')} routePath="/user-settings/data" currentPath={currentPath} />
      <div className="version_text">{`${t('versionNumber')}: ${version}`}</div>
    </>
  );
});

export default UserSettingsNavigation;
