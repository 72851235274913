import React from 'react';
import { Link } from 'react-router-dom';
import { MaterialSetDTO, SetType } from 'dto/materialSet';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import HoverWrapper from 'components/HoverWrapper';
import { useStores } from 'util/mobx/stores';
import { GuideMaterialLikeDTO } from 'dto/guide';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SetGroupListItem from '../SetGroupListItem';
import ListItemDropdownContainer from '../ListItemDropdownContainer';
import GuideMaterialSetListItemMenu from './GuideMaterialSetListItemMenu';
import Instructions from '../components/Instructions';

import './MaterialSetListItem.css';

interface Props extends OptionalLazyLoadProps {
  materialSet: MaterialSetDTO;
  guideData?: GuideMaterialLikeDTO;
  className?: string;
  hideTags?: boolean;
  isClone?: boolean;
  inGuide?: boolean;
  storageLocationMissingIcon?: boolean;
}

const MaterialSetListItem = ({
  materialSet,
  guideData,
  isClone = false,
  inGuide = false,
  className = '',
  hideTags = false,
  storageLocationMissingIcon = false,
  lazyLoadScrollContainer
}: Props) => {
  const { guideDetailDrawerStore, guideStore, materialSetsStore } = useStores();
  const onUpdateFile = async (fileId: string) => {
    await materialSetsStore.updateMaterialSet({ materialSetId: materialSet.materialSetId, pictureFileId: fileId });
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };

  const onOpenMaterialSetDetails = () => {
    if (isClone) {
      return;
    }
    guideDetailDrawerStore.setSelectedMaterialSet({ ...materialSet, materialSetId: materialSet.materialSetId }, guideData);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const onDeleteMaterialSetFromGuide = () => {
    if (guideStore.selectedGuide && guideData) {
      guideStore.deleteGuideMaterial({ guideMaterialId: guideData.guideMaterialId }, guideStore.selectedGuide.guideId);
    }
  };

  const type = SetType[materialSet.type].toLowerCase();

  const renderList = () => (
    <div className="content_holder no_padding padding_left set-group-list">
      {materialSet.setGroups.map(setGroup => {
        return (
          <SetGroupListItem
            lazyLoadScrollContainer={lazyLoadScrollContainer}
            hideTags={hideTags}
            setGroup={setGroup}
            key={setGroup.setGroupId}
            guideData={guideData}
            isClone={isClone}
            hideAmountTag={materialSet.type === SetType.Medicals}
          />
        );
      })}
    </div>
  );

  const renderContent = () => {
    if (guideData && !isClone) {
      return (
        <HoverWrapper className="material_info w-inline-block package-info">
          {({ hover }) => (
            <>
              <div className="material_text material-text" onClick={onOpenMaterialSetDetails}>
                <div>{materialSet.name}</div>
                <Instructions notes={guideData.notes} translationPrefix="materialSet" />
              </div>
              <MaterialValueTags materialSet={materialSet} fullInfo={!!guideData} />
              <div style={{ zIndex: 1000 }}>
                <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                  {({ handleClose, isOpen }) => (
                    <GuideMaterialSetListItemMenu
                      handleClose={handleClose}
                      isOpen={!!isOpen}
                      handleOpenDetails={onOpenMaterialSetDetails}
                      handleDelete={onDeleteMaterialSetFromGuide}
                    />
                  )}
                </Popover>
              </div>
            </>
          )}
        </HoverWrapper>
      );
    }
    if (isClone) {
      return (
        <div className="material_info w-inline-block image-arrow-container list-item">
          <div className="material_text">
            <div>{materialSet.name}</div>
          </div>
          <MaterialValueTags materialSet={materialSet} />
          <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
        </div>
      );
    }
    return (
      <Link
        to={`/group/${type}/overview?materialSetId=${materialSet.materialSetId}`}
        className="material_info w-inline-block image-arrow-container list-item"
      >
        <div className="material_text">
          <div>{materialSet.name}</div>
        </div>
        <MaterialValueTags materialSet={materialSet} />
        <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
      </Link>
    );
  };

  return (
    <ListItemDropdownContainer
      ListElement={renderList}
      classPrefix="material-set"
      inGuide={isClone || inGuide}
      triggerExpandOnClick={isClone}
      className={`${className} ${isClone ? 'w-100' : ''}`}
    >
      {guideData ? (
        <>
          <div className="item_count _1_lvl">
            <div>{guideData.amount}</div>
          </div>
          <div className="item_dropdown_holder" />
        </>
      ) : (
        <></>
      )}

      {materialSet.picture ? (
        // when in a Guide clicking on the Picture should cause bigImageFlyout to show up and show the Image
        // otherwise in the MaterialSetList it should open the MaterialSetDetails
        <div className="image_wrapper_50" onClick={() => !guideData && onOpenMaterialSetDetails()}>
          {storageLocationMissingIcon && (
            <div className="icon_no_location">
              <img src="images/icon_orange_flag.jpg" alt="" />
            </div>
          )}
          <Picture
            withBorder
            size="pictureThumbnail"
            // when in a Guide clicking on the Picture should cause bigImageFlyout to show up and show the Image
            // otherwise in the MaterialSetList it should open the MaterialSetDetails
            src={guideData ? materialSet : materialSet.pictureThumbnail}
            width={50}
            alt=""
            lazyLoadScrollContainer={lazyLoadScrollContainer}
            className="image_circle_50"
          />
        </div>
      ) : (
        <HoverWrapper>
          {({ hover }) => (
            <div className="image_wrapper_50">
              {storageLocationMissingIcon && (
                <div className="icon_no_location">
                  <img src="images/icon_orange_flag.jpg" alt="" />
                </div>
              )}
              <GetCameraImageButton
                buttonStyle="icon"
                onUpdateFile={onUpdateFile}
                description=""
                isAddable
                hover={hover}
                picturesOnly
                withLoadingBar
              />
            </div>
          )}
        </HoverWrapper>
      )}
      <>{renderContent()}</>
    </ListItemDropdownContainer>
  );
};

export default MaterialSetListItem;
