import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import FlyoutContainer from 'components/FlyoutContainer';
import { useTranslation } from 'react-i18next';
import GuideOwnerList from 'pages/guides/grid/content/ownerList/GuideOwnerList';
import { useStores } from 'util/mobx/stores';
import { GuideDTO } from 'dto/guide';
import './SelectGuideFlyout.css';
import Button from 'components/Form/Button';

const SelectGuideFlyout = observer(() => {
  const { surgeryStore, guideStore } = useStores();
  const { t } = useTranslation('surgery');

  const onClose = () => surgeryStore.setIsSelectGuideFlyoutOpen(false);

  useEffect(() => {
    if (surgeryStore.isSelectGuideFlyoutOpen) {
      guideStore.loadGuides();
    }
  }, [guideStore, surgeryStore.isSelectGuideFlyoutOpen]);

  const onAddSurgeryBriefing = (guide: GuideDTO) => {
    surgeryStore.addSurgeryGuide(guide.guideId);
    onClose();
  };

  return (
    <FlyoutContainer
      closeAsSubmit
      isOpen={surgeryStore.isSelectGuideFlyoutOpen}
      closePopUp={onClose}
      cancelLabel={t('selectGuide.button.cancel')}
      isAllowedToSubmit
      icon={<img src="images/icon_book.jpg" width="50" alt="" className="image_circle_40" />}
      title={t('selectGuide.title')}
    >
      <div className="flyout_box">
        <div className="h4">{t(`selectGuide.headline`)}</div>
        <div className="menu_list surgery-select-guide">
          <div className="w-layout-grid grid_colum_content">
            <div className="colum_left in_flyout">
              <GuideOwnerList guideOwnerList={guideStore.guideOwnerList} isCloneGuideFlyout />
            </div>
            <div className="colum_right in_flyout">
              {guideStore.selectedGuides.map(guide => (
                <div className="list_item_book in_flyout list-item" key={guide.guideId}>
                  <div className="image_wrapper_50">
                    <div className="image_border image-border" />
                    <img src="images/icon_book.jpg" alt="" className="image_circle_50" />
                  </div>
                  <div className="book_info book_hover_blue">
                    <div>{guide.name}</div>
                    <div className="btn_show_detail btn-detail-position">
                      <div className="item_number">
                        <div className="txt_number_type">{t('selectGuide.guideId')}</div>
                        <div className="tag-value">{guide.guideNumber}</div>
                      </div>
                      <Button className="btn_small_flyout margin_left_20" onClick={() => onAddSurgeryBriefing(guide)}>
                        <div className="txt_dropout_1">{t('selectGuide.button.select')}</div>
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default SelectGuideFlyout;
