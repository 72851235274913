import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { ProcedureDTO, UpdateSortProceduresDTO } from 'dto/procedure';

import { reorderGuideProcedures } from 'api/procedure';

import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import ProcedureList from './content/ProcedureList';
import GuideProcedureRightMenu from './GuideProcedureRightMenu';

interface Props extends OptionalLazyLoadProps {
  guideId: string;
}

const GuideProcedureContent = observer(({ guideId, lazyLoadScrollContainer }: Props) => {
  const { t } = useTranslation('guideProcedure');
  const { procedureStore, guideStore, appNavigationStore, domainStore, animationStore } = useStores();

  useEffect(() => {
    procedureStore.loadProcedures(guideId);
    return () => procedureStore.resetProcedures();
  }, [procedureStore, guideId, guideStore]);

  appNavigationStore.useSubPageIdSetter('procedures');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let materialToBeMoved: ProcedureDTO;
    let newGuideProcedures = procedureStore.procedures
      ? procedureStore.procedures.map(guideProcedureGroup => {
          if (result.source?.droppableId === guideProcedureGroup.chapterId) {
            const draggableItem = guideProcedureGroup.procedures.find(p => p.guideProcedureId === result.draggableId);
            materialToBeMoved = draggableItem || materialToBeMoved;
            return {
              ...guideProcedureGroup,
              procedures: guideProcedureGroup.procedures.filter(p => p.guideProcedureId !== result.draggableId)
            };
          }
          return guideProcedureGroup;
        })
      : [];
    newGuideProcedures = newGuideProcedures.map(guideProcedureGroup => {
      if (result.destination?.droppableId === guideProcedureGroup.chapterId) {
        guideProcedureGroup.procedures.splice(result.destination.index, 0, materialToBeMoved);
        return {
          ...guideProcedureGroup,
          procedures: guideProcedureGroup.procedures
        };
      }
      return guideProcedureGroup;
    });
    const positions: UpdateSortProceduresDTO[] = newGuideProcedures.map(guideProcedureGroup => {
      const guideProceduresIds = guideProcedureGroup.procedures.map(p => p.guideProcedureId);
      return {
        chapterId: guideProcedureGroup.chapterId,
        guideProceduresIds
      };
    });
    reorderGuideProcedures(positions);
    procedureStore.setProcedures(newGuideProcedures);
  };

  const setDataForNewProcedure = useCallback(
    (chapterId?: string, position?: number) => {
      if (chapterId) {
        procedureStore.setNewProcedureData(chapterId, position);
      } else if (procedureStore.procedures && procedureStore.procedures.length > 0) {
        procedureStore.setNewProcedureData(procedureStore.procedures[0].chapterId, position);
      }
    },
    [procedureStore]
  );

  /**
   * @param position if undefined, it is added at the end
   */
  const handleAddProcedure = useCallback(
    (chapterId?: string, position?: number) => {
      setDataForNewProcedure(chapterId, position);
      procedureStore.setIsProcedureFormFlyoutOpen(true);
      animationStore.resetElementIdToShake();
    },
    [procedureStore, setDataForNewProcedure, animationStore]
  );

  /**
   * @param position if undefined, it is added at the end
   */
  const handleCloneProcedure = useCallback(
    (chapterId?: string, position?: number) => {
      setDataForNewProcedure(chapterId, position);
      const currentDepartmentId = domainStore.currentDepartment.id;
      if (guideStore.selectedGuide && currentDepartmentId && domainStore.departmentsByCurrentFunctionArea) {
        const departmentsIds = domainStore.departmentsByCurrentFunctionArea.departments.map(department => department.departmentId);
        guideStore.setGuideProcedureToClone(guideStore.selectedGuide.guideId);
        guideStore.getGuidesByDepartmentsClone(departmentsIds);
        guideStore.setShowCloneGuideProcedureFlyout(true);
      }
    },
    [guideStore, domainStore, setDataForNewProcedure]
  );

  /**
   * @param position if undefined, it is added at the end
   */
  const handleCloneMaterialKnowledge = useCallback(
    (chapterId?: string, position?: number) => {
      setDataForNewProcedure(chapterId, position);
      if (guideStore.selectedGuide) {
        guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
        guideStore.setShowCloneMaterialKnowledgeFlyout(true);
      }
    },
    [guideStore, setDataForNewProcedure]
  );

  // set the component for the right menu
  useEffect(() => {
    if (procedureStore.procedures && procedureStore.procedures.length > 0 && procedureStore.procedures[0]) {
      appNavigationStore.setRightMenuBuilder(() => {
        if (!procedureStore.procedures || procedureStore.procedures.length === 0) {
          return <></>;
        }
        const chapterId = procedureStore.procedures[0].chapterId;
        return (
          <GuideProcedureRightMenu
            handleCloneMaterialKnowledge={() => handleCloneMaterialKnowledge(chapterId)}
            handleCloneProcedure={() => handleCloneProcedure(chapterId)}
            handleCreateProcedureFlyoutOpen={() => handleAddProcedure(chapterId)}
          />
        );
      });
    }
  }, [appNavigationStore, handleAddProcedure, handleCloneProcedure, handleCloneMaterialKnowledge, procedureStore.procedures]);

  return (
    <div className="single_colum_content">
      <DragDropContext onDragEnd={onDragEnd}>
        {procedureStore.procedures ? (
          procedureStore.procedures.map((procedureItem, index) => (
            <Droppable droppableId={procedureItem.chapterId} key={procedureItem.chapterId}>
              {provided => (
                <div ref={provided.innerRef}>
                  <div id={procedureItem.chapter}>
                    <div className={index > 0 ? 'h1 top-margin' : 'h1'}>{procedureItem.chapter}</div>
                    <ProcedureList
                      chapterId={procedureItem.chapterId}
                      procedures={procedureItem.procedures}
                      onFlyoutOpen={handleAddProcedure}
                      handleCloneProcedure={handleCloneProcedure}
                      handleCloneMaterialKnowledge={handleCloneMaterialKnowledge}
                      lazyLoadScrollContainer={lazyLoadScrollContainer}
                    />
                    {provided.placeholder}
                    <Can
                      I={actions.add}
                      this={subject(subjectArea.guideProcedure, { departmentId: guideStore.selectedGuide?.departmentId })}
                    >
                      <ListItemAdd className="post_item_add top-margin">
                        <InfoBox label={t('button.addProcedure')} onClick={() => handleAddProcedure(procedureItem.chapterId)} />
                        <InfoBox label={t('button.getProcedure')} onClick={() => handleCloneProcedure(procedureItem.chapterId)} />
                        <InfoBox label={t('button.getMaterial')} onClick={() => handleCloneMaterialKnowledge(procedureItem.chapterId)} />
                      </ListItemAdd>
                    </Can>
                  </div>
                </div>
              )}
            </Droppable>
          ))
        ) : (
          <></>
        )}
      </DragDropContext>
    </div>
  );
});

export default GuideProcedureContent;
