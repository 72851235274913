import React, { ChangeEvent, createRef, useEffect } from 'react';
import { DropzoneRef } from 'react-dropzone';
import { saveFileContentElement } from 'api/file';
import { FormikProps, FormikValues } from 'formik';
import { getFileName, getMediaType, getFileExtension } from 'util/fileUtils';
import ThumbnailList from 'components/ThumbnailList';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/UploadMultipleFiles';
import './CommentInput.css';
import DropzoneContainer, { OnDropCallback } from 'components/DropzoneContainer';
import { ProcessingStatus } from 'dto/file';

interface Props extends React.HTMLProps<HTMLDivElement> {
  placeholder: string;
  isExpanded: boolean | null;
  onInputClick?: () => void;
  meta: FormikProps<FormikValues>;
  name: string;
  setUploadedFiles: React.Dispatch<React.SetStateAction<MixedContentElementMediaDTO[]>>;
  uploadedFiles: MixedContentElementMediaDTO[];
}

const CommentInput = ({ placeholder, onInputClick, isExpanded, name, meta, setUploadedFiles, uploadedFiles, ...rest }: Props) => {
  const dropzoneRef = createRef<DropzoneRef>();
  const textareaRef = createRef<HTMLTextAreaElement>();

  const gotImage = (fakeId: string, file: MixedContentElementMediaDTO) => {
    meta.setFieldValue('contentElementMedias', [...meta.values.contentElementMedias, file]);
    setUploadedFiles(current => [
      ...current.map(oldFile => {
        if (oldFile.fileId === fakeId) {
          return file;
        }
        return oldFile;
      })
    ]);
  };

  useEffect(() => {
    setUploadedFiles(meta.values.contentElementMedias);
  }, [meta, setUploadedFiles]);

  const onInputFocus = () => {
    if (onInputClick) {
      onInputClick();
    }
  };
  const onClick = () => {
    if (onInputClick) {
      onInputClick();
    }
  };

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (name) {
      meta.setFieldValue(name, event.target.value);
    }
  };

  const onFilesChange = (updatedFiles: MixedContentElementMediaDTO[]) => {
    meta.setFieldValue('contentElementMedias', [...updatedFiles]);
  };

  const onSaveFile = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const progressFileId = (file.lastModified + Math.random() * 100).toString();

    const response = await saveFileContentElement(bodyFormData, e => {
      setUploadedFiles(current => {
        const extension = getFileExtension(file.name);
        const fileInProgress = current.find(f => f.fileId === progressFileId);
        if (fileInProgress) {
          const updatedFiles = current.map(f => {
            if (f.fileId === progressFileId) {
              return {
                ...f,
                progress: Math.round((e.loaded / e.total) * 100)
              };
            }
            return f;
          });
          return [...updatedFiles];
        }
        const newFile: MixedContentElementMediaDTO = {
          ...file,
          contentType: getMediaType(extension),
          progress: Math.round((e.loaded / e.total) * 100),
          fileId: progressFileId,
          status: ProcessingStatus.Uploading,
          fullUrl: '',
          originalFileName: ''
        };
        return [...current, newFile];
      });
    });

    const title = getFileName(file.name, file.type);
    if (response) {
      gotImage(progressFileId, {
        ...response,
        title
      });
    }
  };

  const onDrop: OnDropCallback = async acceptedFiles => {
    const file = acceptedFiles[0];
    await onSaveFile(file);
  };

  return (
    <DropzoneContainer ref={dropzoneRef} onDrop={onDrop} className="dropzone-container">
      <div
        className={`input_field left-margin comment-input-container ${isExpanded ? 'expanded input_field-copy' : 'collapsed'}`}
        {...rest}
        onClick={onClick}
      >
        <textarea
          onFocus={onInputFocus}
          onChange={onCommentChange}
          value={meta.values[name]}
          placeholder={placeholder}
          name={name}
          autoComplete="off"
          ref={textareaRef}
        />
        <ThumbnailList saveFile={saveFileContentElement} onChange={onFilesChange} files={uploadedFiles} inComment />
      </div>
    </DropzoneContainer>
  );
};

export default CommentInput;
