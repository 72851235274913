import React from 'react';
import { useStores } from 'util/mobx/stores';
import { InstrumentDTO } from 'dto/instrument';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import HoverWrapper from 'components/HoverWrapper';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import GetCameraImageButton from 'components/GetCameraImageButton';
import MaterialValueTags from 'components/MaterialValueTags';
import Picture from 'components/Picture';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryInstrumentListItemMenu from './SurgeryInstrumentListItemMenu';

import './SurgeryInstrumentListItem.css';

interface Props extends OptionalLazyLoadProps {
  instrument: InstrumentDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
}

const SurgeryInstrumentListItem = ({ instrument, surgeryGuide, lazyLoadScrollContainer }: Props) => {
  const { instrumentStore, surgeryGuideStore, guideDetailDrawerStore } = useStores();
  const onUpdateFile = async (fileId: string) => {
    await instrumentStore.updateMaterial({ materialId: instrument.material.materialId, pictureFileId: fileId });
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideStore.surgeryGuide.surgeryGuideId);
    }
  };
  const onOpenDetails = () => {
    guideDetailDrawerStore.setSelectedInstrument(instrument);
    guideDetailDrawerStore.setSelectedSurgeryGuideItem(surgeryGuide);
    guideDetailDrawerStore.setIsOpen(true);
  };

  return (
    <HoverWrapper className="surgery-instrument-item">
      {({ hover }) => {
        return (
          <div className="list_item_material margin-right w-inline-block package-info image-border-container">
            <div className="list_item_material margin-right image-border-container">
              <div className="item_count" onClick={onOpenDetails}>
                <div>{instrument.amount}</div>
              </div>
              <div className="image_wrapper_50">
                {instrument.material.picture ? (
                  <Picture
                    withBorder
                    size="pictureThumbnail"
                    src={instrument.material}
                    width={50}
                    alt=""
                    lazyLoadScrollContainer={lazyLoadScrollContainer}
                    className="image_circle_50"
                  />
                ) : (
                  <GetCameraImageButton
                    buttonStyle="icon"
                    description=""
                    picturesOnly
                    onUpdateFile={onUpdateFile}
                    isAddable
                    withLoadingBar
                  />
                )}
                <div className="material_lvl1_line lvl-line">
                  <div className="dot in_mat_list" />
                </div>
              </div>
              <div className="material_info instrument-info">
                <div className="material_text" onClick={onOpenDetails}>
                  <div>{instrument.material.name}</div>
                </div>
                <MaterialValueTags instrument={instrument} onClick={onOpenDetails} />
                <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                  {({ handleClose, isOpen }) => (
                    <SurgeryInstrumentListItemMenu onOpenDetails={onOpenDetails} handleClose={handleClose} isOpen={!!isOpen} />
                  )}
                </Popover>
              </div>
            </div>
          </div>
        );
      }}
    </HoverWrapper>
  );
};

export default SurgeryInstrumentListItem;
