import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useStores } from 'util/mobx/stores';
import { GroupedGuideMaterialsDTO, GuideMaterialLikeDTO } from 'dto/guide';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import GuideMaterialGroup from './content/GuideMaterialGroup';
import GuideMaterialRightMenu from './GuideMaterialRightMenu';

import './content/GuideMaterials.css';

type Props = OptionalLazyLoadProps;

const GuideMaterialsContent = observer(({ lazyLoadScrollContainer }: Props) => {
  const { guideStore, guideDetailDrawerStore, appNavigationStore } = useStores();

  useEffect(() => {
    guideDetailDrawerStore.enableSettings();
    return () => {
      guideDetailDrawerStore.disableSettings();
    };
  });

  useEffect(() => {
    async function loadData() {
      if (!guideStore.selectedGuide?.guideId) {
        return;
      }
      await guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
    loadData();
    return () => {
      guideStore.clearGuideMaterials();
    };
  }, [guideStore, guideStore.selectedGuide]);

  // set the component for the right menu
  useEffect(() => {
    if (guideStore.guideMaterials.length > 0 && guideStore.guideMaterials[0]) {
      appNavigationStore.setRightMenuBuilder(() => {
        return <GuideMaterialRightMenu />;
      });
    }
  }, [appNavigationStore, guideStore.guideMaterials]);

  appNavigationStore.useSubPageIdSetter('materials');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let materialToBeMoved: GuideMaterialLikeDTO;
    let newGuideMaterial = guideStore.guideMaterials.map(guideMaterialGroup => {
      if (result.source?.droppableId === guideMaterialGroup.categoryId) {
        const draggableItem = guideMaterialGroup.materials.find(m => m.guideMaterialId === result.draggableId);
        materialToBeMoved = draggableItem || materialToBeMoved;
        return {
          ...guideMaterialGroup,
          materials: guideMaterialGroup.materials.filter(m => m.guideMaterialId !== result.draggableId)
        };
      }
      return guideMaterialGroup;
    });
    newGuideMaterial = newGuideMaterial.map(guideMaterialGroup => {
      if (result.destination?.droppableId === guideMaterialGroup.categoryId) {
        guideMaterialGroup.materials.splice(result.destination.index, 0, materialToBeMoved);
        return {
          ...guideMaterialGroup,
          materials: guideMaterialGroup.materials
        };
      }
      return guideMaterialGroup;
    });
    guideStore.reorderGuideMaterials(newGuideMaterial);
  };

  return (
    <div className="single_colum_content">
      <DragDropContext onDragEnd={onDragEnd}>
        {guideStore.guideMaterials.map((guideMaterialGroup: GroupedGuideMaterialsDTO, index: number) => (
          <Droppable droppableId={guideMaterialGroup.categoryId} key={guideMaterialGroup.categoryId}>
            {provided => (
              <div ref={provided.innerRef} className="single-colum-content-item">
                <GuideMaterialGroup
                  guideMaterialGroup={guideMaterialGroup}
                  className={index > 0 ? 'h1 top-margin' : 'h1'}
                  key={guideMaterialGroup.category}
                  lazyLoadScrollContainer={lazyLoadScrollContainer}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
});

export default GuideMaterialsContent;
