import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { CreatePostContentElementDTO } from 'dto/contentElement';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { PostDTO } from 'dto/post';
import ChatBar from './ChatBar';
import ChatFlyout from './ChatFlyout';

export enum ChatConfigurationTypes {
  guide = 'guide',
  department = 'department',
  briefing = 'briefing'
}

interface Props {
  chatConfigurationType: ChatConfigurationTypes;
}

interface ChatConfigurationType {
  loadPosts: () => void;
  createPost: (post: CreatePostContentElementDTO) => void;
  placeholder: string;
  posts?: PostDTO[];
  flyoutTitle: string;
  withoutCommentsFlyout?: boolean;
  boardOnly?: boolean;
}

const Chat = observer(({ chatConfigurationType }: Props) => {
  const { postStore, guideStore, domainStore, surgeryStore, appNavigationStore } = useStores();
  const { t } = useTranslation('chat');

  const loadGuidePosts = () => {
    if (guideStore.selectedGuide) {
      return postStore.loadGuidePosts(guideStore.selectedGuide?.guideId);
    }
    return null;
  };

  const loadDepartmentPosts = () => {
    if (domainStore.currentDepartment) {
      return postStore.loadDepartmentPosts(domainStore.currentDepartment.id);
    }
    return null;
  };

  const loadBriefingPosts = () => {
    if (surgeryStore.selectedSurgeryBriefing) {
      return postStore.loadBriefingPosts(surgeryStore.selectedSurgeryBriefing.briefingId);
    }
    return null;
  };

  const createBriefingPost = async (post: CreatePostContentElementDTO) => {
    if (surgeryStore.selectedSurgeryBriefing) {
      await postStore.createBriefingPost(surgeryStore.selectedSurgeryBriefing.briefingId, post);
      surgeryStore.refreshSurgeriesInSelectedRoom();
    }
  };

  const getChatData = (type: ChatConfigurationTypes): ChatConfigurationType | undefined => {
    if (type === ChatConfigurationTypes.guide && guideStore.selectedGuide) {
      return {
        placeholder: t('guideChatPlaceholder', { guideName: guideStore.selectedGuide?.name }),
        loadPosts: loadGuidePosts,
        createPost: (post: CreatePostContentElementDTO) =>
          guideStore.selectedGuide && postStore.createGuidePost(guideStore.selectedGuide.guideId, post),
        posts: postStore.guidePosts,
        flyoutTitle: t('flyout.guideTitle')
      };
    }
    if (type === ChatConfigurationTypes.briefing && surgeryStore.selectedSurgeryBriefing) {
      return {
        loadPosts: loadBriefingPosts,
        placeholder: t('briefingChatPlaceholder', { surgeryName: surgeryStore.selectedSurgeryBriefing.surgery.name }),
        createPost: createBriefingPost,
        posts: postStore.briefingPosts,
        flyoutTitle: t('flyout.briefingTitle'),
        withoutCommentsFlyout: true,
        boardOnly: true
      };
    }
    if (type === ChatConfigurationTypes.department) {
      return {
        placeholder: t('departmentChatPlaceholder', { departmentName: domainStore.currentDepartment.name }),
        loadPosts: loadDepartmentPosts,
        createPost: (post: CreatePostContentElementDTO) =>
          domainStore.currentDepartment && postStore.createDepartmentPost(domainStore.currentDepartment.id, post),
        posts: postStore.departmentPosts,
        flyoutTitle: t('flyout.departmentTitle')
      };
    }
    return undefined;
  };

  const chatData = getChatData(chatConfigurationType);
  useEffect(() => {
    if (postStore.isChatBarExpanded && chatData) {
      chatData.loadPosts();
    }
    // eslint-disable-next-line
  }, [postStore, postStore.isChatBarExpanded]);
  if (!chatData) {
    return null;
  }
  return (
    <>
      {(postStore.isChatBarExpanded && appNavigationStore.isKeyboardOpen) || !appNavigationStore.isKeyboardOpen ? (
        <ChatBar placeholder={chatData.placeholder} createPost={chatData.createPost} />
      ) : (
        <></>
      )}
      {!chatData.boardOnly && <ChatFlyout posts={chatData.posts} flyoutTitle={chatData.flyoutTitle} />}
    </>
  );
});

export default Chat;
