import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import CommentInput from 'components/Form/CommentInput';
import { CreatePostContentElementDTO } from 'dto/contentElement';
import { useStores } from 'util/mobx/stores';
import ImageCircle from 'components/ImageCircle';
import { FormikProps, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/UploadMultipleFiles';
import { ProcessingStatus } from 'dto/file';
import PictureHelper from '../PictureHelper';
import GetCameraImageButtonWrapper from '../GetCameraImageButtonWrapper';

interface Props {
  placeholder: string;
  createPost: (post: CreatePostContentElementDTO) => void;
}

const ChatForm = observer(({ placeholder, createPost }: Props) => {
  const { t } = useTranslation('chat');
  const { postStore, authStore } = useStores();
  const [uploadedFiles, setUploadedFiles] = useState<MixedContentElementMediaDTO[]>([]);

  const onChatOpen = () => {
    postStore.setIsChatBarExpanded(true);
    postStore.setIsChatFlyoutOpen(true);
    postStore.setIsChatFlyoutCollapsible(true);
  };

  const onChatClose = (form?: FormikProps<FormikValues>) => {
    postStore.setIsChatBarExpanded(false);
    postStore.setIsChatFlyoutOpen(false);
    if (form) form.resetForm();
    setTimeout(() => {
      postStore.setIsChatFlyoutCollapsible(false);
    }, 600);
  };

  const onSubmit = async ({ description, contentElementMedias }: CreatePostContentElementDTO) => {
    const post = {
      description,
      contentElementMedias: contentElementMedias ? contentElementMedias.map(e => ({ fileId: e.fileId, title: e.title })) : []
    };
    await createPost(post);
    onChatClose();
  };

  return (
    <Form
      initialValues={{
        description: '',
        contentElementMedias: []
      }}
      onSubmit={onSubmit}
    >
      {form => (
        <>
          <PictureHelper />
          <div className="div-block-56">
            <ImageCircle className="image_circle_chat_avatar image-circle-chat-avatar" user={authStore.user} size="40" highlight={false} />
            <GetCameraImageButtonWrapper meta={form} isExpanded={postStore.isChatBarExpanded} isAddable />
          </div>
          <div className="div-block-73 chat-bar-form-container">
            <CommentInput
              meta={form}
              name="description"
              isExpanded={postStore.isChatBarExpanded}
              placeholder={placeholder}
              onInputClick={onChatOpen}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
            />
            <div className="div-block-74">
              <Button className="btn_msg_cancel" type="button" onClick={() => onChatClose(form)}>
                {t('inputForm.buttons.abort')}
              </Button>

              <Button
                disabled={
                  !form.dirty ||
                  !form.values.description ||
                  (uploadedFiles && uploadedFiles.filter(uF => uF.status === ProcessingStatus.Uploading).length > 0)
                }
                className="btn_msg_send"
              >
                {t('inputForm.buttons.publish')}
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
});

export default ChatForm;
