import { observable, runInAction, action } from 'mobx';
import { MaterialDTO, MaterialLikeDTO, MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import { InstrumentDTO } from 'dto/instrument';
import { GuideMaterialLikeDTO, NotesDTO, UpdateGuideMaterialDTO } from 'dto/guide';
import { getMaterial, getMaterialStorageLocations, getMaterialStorageLocationPaths } from 'api/material';
import { getInstrument } from 'api/instrument';
import { getPackage } from 'api/package';
import { getMaterialSet } from 'api/materialSet';
import { PackageDTO } from 'dto/package';
import { BasicMaterialSetDTO } from 'dto/materialSet';
import { getStorageLocationPath } from 'api/storageLocation';
import { updateSurgeryGuideMaterial } from 'api/surgeryGuide';
import { updateGuideMaterial, deleteGuideMaterial } from 'api/guide';
import { StorageLocationPathDTO, MaterialStorageLocationsDTO } from 'dto/storageLocation';
import { SurgeryGuideMaterialLikeDTO, UpdateSurgeryGuideMaterialDTO } from 'dto/surgeryGuide';
import LoadingStore from './loadingStore';

export interface GuideSettingsValues {
  amount: number;
  notes: NotesDTO;
}

export interface MergedGuideMaterialDTO extends MaterialLikeDTO {
  guideMaterialId?: string;
  surgeryGuideMaterialId?: string;
  storageLocationId?: string;
  amount: number;
  notes: NotesDTO;
}

export default class GuideDetailDrawerStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  isOpen = false;

  /**
   * Use this instead of the knowledgeStore.isCreateMaterialSynonymOpen for the
   * Synonym flyout inside of the drawer.
   */
  @observable
  isCreateMaterialSynonymOpen = false;

  @observable
  selectedMaterial?: MaterialDTO;

  @observable
  selectedPackage?: PackageDTO;

  @observable
  selectedInstrument?: InstrumentDTO;

  @observable
  selectedMaterialSet?: BasicMaterialSetDTO;

  @observable
  selectedGuideItem?: GuideMaterialLikeDTO;

  @observable
  selectedSurgeryGuideItem?: SurgeryGuideMaterialLikeDTO;

  @observable
  storageLocationPath?: StorageLocationPathDTO;

  @observable
  materialStorageLocationPaths?: StorageLocationPathDTO[];

  @observable
  materialStorageLocations?: MaterialStorageLocationsDTO[];

  @observable
  guideSettingsValues?: GuideSettingsValues;

  @observable
  choosingLocationDisabled = false;

  @observable
  settingsEnabled = false;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  @action
  setSelectedMaterial(selectedMaterial: MaterialDTO, selectedGuideMaterial?: GuideMaterialLikeDTO, choosingLocationDisabled = false) {
    this.clearAllSelectedItems(false);
    this.selectedMaterial = selectedMaterial;
    if (selectedGuideMaterial) {
      this.selectedGuideItem = selectedGuideMaterial;
    }
    this.choosingLocationDisabled = choosingLocationDisabled;
  }

  @action
  setSelectedPackage(selectedPackage: PackageDTO, selectedGuideTemplate?: GuideMaterialLikeDTO) {
    this.clearAllSelectedItems(false);
    this.selectedPackage = selectedPackage;
    if (selectedGuideTemplate) {
      this.selectedGuideItem = selectedGuideTemplate;
    }
  }

  @action
  setSelectedInstrument(selectedInstrument: InstrumentDTO, selectedGuideTemplate?: GuideMaterialLikeDTO) {
    this.clearAllSelectedItems(false);
    this.selectedInstrument = selectedInstrument;
    if (selectedGuideTemplate) {
      this.selectedGuideItem = selectedGuideTemplate;
    }
  }

  @action
  setSelectedMaterialSet(selectedMaterialSet: BasicMaterialSetDTO, selectedGuideMaterialSet?: GuideMaterialLikeDTO) {
    this.clearAllSelectedItems(false);
    this.selectedMaterialSet = selectedMaterialSet;
    if (selectedGuideMaterialSet) {
      this.selectedGuideItem = selectedGuideMaterialSet;
    }
  }

  @action
  setSelectedGuideItem(selectedGuideItem: GuideMaterialLikeDTO) {
    this.selectedGuideItem = selectedGuideItem;
  }

  @action
  setSelectedSurgeryGuideItem(selectedSurgeryGuideItem: SurgeryGuideMaterialLikeDTO) {
    this.selectedSurgeryGuideItem = selectedSurgeryGuideItem;
  }

  @action
  setIsOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    if (!isOpen) {
      this.clearAllSelectedItems();
    }
  }

  @action
  clearAllSelectedItems(withTimeout = true) {
    const reset = () => {
      this.selectedMaterial = undefined;
      this.selectedPackage = undefined;
      this.selectedInstrument = undefined;
      this.selectedMaterialSet = undefined;
      this.selectedGuideItem = undefined;
      this.choosingLocationDisabled = false;
      this.selectedSurgeryGuideItem = undefined;
    };

    if (withTimeout) {
      // wait for animation complete
      setTimeout(() => {
        runInAction(reset);
      }, 500);
    } else {
      reset();
    }
  }

  async reloadSelectedMaterial() {
    const selectedMaterial = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedMaterial) {
        return Promise.resolve(undefined);
      }
      return getMaterial(this.selectedMaterial.materialId);
    });
    runInAction(() => {
      this.selectedMaterial = selectedMaterial;
    });
  }

  async reloadSelectedPackage() {
    const fullPackage = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedPackage) {
        return Promise.resolve(undefined);
      }
      return getPackage(this.selectedPackage.packageId);
    });
    runInAction(() => {
      if (fullPackage) {
        this.selectedPackage = fullPackage.pack;
      }
    });
  }

  async reloadSelectedInstrument() {
    const selectedInstrument = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedInstrument) {
        return Promise.resolve(undefined);
      }
      return getInstrument(this.selectedInstrument.instrumentId);
    });
    runInAction(() => {
      this.selectedInstrument = selectedInstrument;
    });
  }

  async reloadSelectedMaterialSet() {
    const selectedMaterialSet = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedMaterialSet) {
        return Promise.resolve(undefined);
      }
      return getMaterialSet(this.selectedMaterialSet.materialSetId);
    });
    runInAction(() => {
      if (selectedMaterialSet) {
        this.selectedMaterialSet = {
          ...selectedMaterialSet,
          materialSetId: selectedMaterialSet.materialSetId
        };
      }
    });
  }

  async loadStorageLocationPath(storageLocationId: string) {
    const storageLocationPath = await this.loadingStore.withLoadingBar(() => {
      return getStorageLocationPath(storageLocationId);
    });
    runInAction(() => {
      this.storageLocationPath = storageLocationPath;
    });
  }

  async updateGuideMaterial(guide: UpdateGuideMaterialDTO, callback: () => Promise<void>) {
    await this.loadingStore.withLoadingBar(() => {
      return updateGuideMaterial(guide);
    });
    callback();
  }

  async updateSurgeryGuideMaterial(guide: UpdateSurgeryGuideMaterialDTO, callback: () => void) {
    await this.loadingStore.withLoadingBar(() => {
      return updateSurgeryGuideMaterial(guide);
    });
    callback();
  }

  async deleteGuideMaterial(guideMaterialId: string, callback: () => Promise<void>) {
    await this.loadingStore.withLoadingBar(() => {
      return deleteGuideMaterial({ guideMaterialId });
    });
    callback();
  }

  async loadMaterialStorageLocationPaths(materialLikeId: MaterialLikeIdOrPackageIdDTO, functionalAreaId?: string) {
    const materialStorageLocationPaths = await this.loadingStore.withLoadingBar(() => {
      return getMaterialStorageLocationPaths({
        functionalAreaId,
        ...materialLikeId
      });
    });
    const materialStorageLocations = await this.loadingStore.withLoadingBar(() => {
      return getMaterialStorageLocations({
        functionalAreaId,
        ...materialLikeId
      });
    });
    runInAction(() => {
      this.materialStorageLocationPaths = materialStorageLocationPaths;
      this.materialStorageLocations = materialStorageLocations;
    });
  }

  @action
  resetStorageLocationData() {
    this.storageLocationPath = undefined;
    this.materialStorageLocationPaths = undefined;
    this.materialStorageLocations = undefined;
  }

  @action
  openCreateMaterialSynonymFlyout() {
    this.isCreateMaterialSynonymOpen = true;
  }

  @action
  closeCreateMaterialSynonymFlyout() {
    this.isCreateMaterialSynonymOpen = false;
  }

  @action
  setGuideSettingsValues(guideSettingsValues: GuideSettingsValues) {
    this.guideSettingsValues = guideSettingsValues;
  }

  @action
  clearStorageLocationPath() {
    this.storageLocationPath = undefined;
  }

  @action
  enableSettings() {
    this.settingsEnabled = true;
  }

  @action
  disableSettings() {
    this.settingsEnabled = false;
  }
}
