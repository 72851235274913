import React, { useState } from 'react';
import Form from 'components/Form';
import { CreatePostContentElementDTO } from 'dto/contentElement';
import { useStores } from 'util/mobx/stores';
import { getAnimationState } from 'util/animation';
import DialogFormContent from './DialogFormContent/DialogFormContent';

import './DialogForm.css';

interface Props {
  afterSubmit: () => void;
}

const DialogForm = ({ afterSubmit }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean | null>(null);
  const { postStore, domainStore } = useStores();

  const onSubmit = async ({ description, contentElementMedias }: CreatePostContentElementDTO) => {
    const post = {
      description,
      contentElementMedias: contentElementMedias ? contentElementMedias.map(e => ({ fileId: e.fileId, title: e.title })) : []
    };
    await postStore.createFunctionalAreaPost(domainStore.currentFunctionalArea.id, post);
    setIsExpanded(false);
    afterSubmit();
  };

  return (
    <div className={`div-block-88 dialog-form-container ${getAnimationState(isExpanded, 'expanded', 'collapsed')}`}>
      <div className="b_chat_line" />
      <Form
        initialValues={{
          description: '',
          contentElementMedias: []
        }}
        onSubmit={onSubmit}
      >
        {form => <DialogFormContent form={form} setIsExpanded={setIsExpanded} isExpanded={isExpanded} />}
      </Form>
    </div>
  );
};

export default DialogForm;
