import React, { useEffect } from 'react';
import { Route, Switch, useLocation, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';

import { useStores } from 'util/mobx/stores';
import MaterialSetOverview from 'pages/materialSetOverview/MaterialSetOverview';
import MaterialSetDetail from 'pages/materialSetDetail/MaterialSetDetail';
import MaterialSetContent from 'pages/materialSetContent/MaterialSetContent';
import MaterialSetGlobalFunctions from 'pages/materialSetGlobalFunctions/MaterialSetGlobalFunctions';
import MaterialSetStorageLocations from 'pages/materialSetStorageLocations';
import MaterialSetGroups from 'pages/materialSetGroups/MaterialSetGroups';
import MaterialKnowledgeFormFlyout from 'components/MaterialKnowledge/MaterialKnowledgeFormFlyout';
import Safe from 'components/Safe';
import Page from 'components/Page';
import AppLayout from 'components/AppLayout';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import SetGroupFormFlyout from 'pages/materialSetGroups/SetGroupFormFlyout';
import SelectLocationFlyout from 'pages/flyouts/SelectLocationFlyout';
import StorageLocationDetailDrawer from 'pages/locationAdministration/StorageLocationDetailDrawer';
import CheckItemsListFlyout from 'pages/materialSetGroups/CheckItemsListFlyout';
import { AppBarPageIcon } from 'components/AppLayout/AppBar';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import MaterialValueTags from 'components/MaterialValueTags';
import { SetType } from 'dto/materialSet';
import { getValueByKey } from 'util/enum';
import { observer } from 'mobx-react';
import MaterialSetNavSection from './MaterialSetNavSection';
import DeleteMaterialSetFlyout from './DeleteMaterialSetFlyout';
import MaterialSetHeaderText from './MaterialSetHeaderText';

interface MatchParams {
  groupType: string;
}

type Props = RouteComponentProps<MatchParams>;

const MaterialSet = observer(({ match }: Props) => {
  const { materialSetsStore } = useStores();
  const { t } = useTranslation(match.params.groupType);
  const location = useLocation();
  const materialSetId = qs.parse(location.search).materialSetId as string;
  useEffect(() => {
    materialSetsStore.setMaterialSetId(materialSetId);
    materialSetsStore.loadMaterialSet(materialSetId);
  }, [materialSetId, materialSetsStore]);

  const image = materialSetsStore.materialSetDetail?.pictureThumbnail;
  const medicalTypeString = getValueByKey(SetType, SetType.Medicals);

  const onUpdateFile = async (fileId: string) => {
    if (materialSetsStore.materialSetDetail) {
      await materialSetsStore.updateMaterialSet({
        materialSetId: materialSetsStore.materialSetDetail.materialSetId,
        pictureFileId: fileId
      });
    }
  };
  return (
    <AppLayout
      appBarIcon={<AppBarPageIcon key={image} src={image} onUpdateFile={onUpdateFile} />}
      appBarChildren={<MaterialSetHeaderText />}
      boardFlyout={<BoardFlyout />}
      drawer={<StorageLocationDetailDrawer />}
      appBarRightChildren={<MaterialValueTags fullInfo classNameTitle="margin_left" materialSet={materialSetsStore.materialSetDetail} />}
      flyout={
        <>
          <MaterialKnowledgeFormFlyout />
          <CreateMaterialSynonymFlyout />
          <DeleteMaterialSetFlyout groupType={match.params.groupType} />
          <SetGroupFormFlyout />
          <CheckItemsListFlyout groupType={match.params.groupType} />
          <SelectLocationFlyout flyoutHeadline="selectLocationFlyout.headlineForMaterialSets" />
        </>
      }
    >
      <Page
        headline={t('subMenu.headline')}
        idPrefix="material-set"
        navigation={
          <MaterialSetNavSection
            materialSetId={materialSetId}
            groupType={match.params.groupType}
            hideStorageLocation={match.params.groupType === medicalTypeString}
          />
        }
        chatConfigurationType={ChatConfigurationTypes.department}
        gridClass="grid_main_checkable"
        wrapperContentClass={`${location.pathname.includes('list') ? 'material-set-groups-content flex_content' : ''}`}
      >
        <Switch>
          <Route
            path="/group/:groupType/overview"
            component={() => (
              <MaterialSetOverview
                materialSetId={materialSetId}
                groupType={match.params.groupType}
                hideQrCode={match.params.groupType === medicalTypeString}
                hideStorageLocation={match.params.groupType === medicalTypeString}
              />
            )}
          />
          <Route path="/group/:groupType/detail" component={() => <MaterialSetDetail groupType={match.params.groupType} />} />
          <Route
            path="/group/:groupType/list"
            component={() => (
              <MaterialSetGroups groupType={match.params.groupType} checkListDisabled={match.params.groupType === medicalTypeString} />
            )}
          />
          <Route
            path="/group/:groupType/content"
            component={() => <MaterialSetContent groupType={match.params.groupType} materialSetId={materialSetId} />}
          />
          <Route
            path="/group/:groupType/functions/"
            component={() => <MaterialSetGlobalFunctions groupType={match.params.groupType} materialSetId={materialSetId} />}
          />
          <Route path="/group/:groupType/locations/" component={() => <MaterialSetStorageLocations materialSetId={materialSetId} />} />
        </Switch>
      </Page>
    </AppLayout>
  );
});

export default (props: Props) => (
  <Safe>
    <MaterialSet {...props} />
  </Safe>
);
