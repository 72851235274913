import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { SurgeryProcedureDTO, UpdateSortSurgeryGuideProceduresDTO } from 'dto/surgeryGuideProcedure';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import UsedGuide from './UsedGuide';
import SurgeryProceduresList from './SurgeryProceduresList';
import SurgeryProceduresRightMenu from './SurgeryProceduresRightMenu';

import './SurgeryProcedures.css';

interface Props extends OptionalLazyLoadProps {
  surgeryGuideId: string;
}

const SurgeryProcedures = observer(({ surgeryGuideId, lazyLoadScrollContainer }: Props) => {
  const { appNavigationStore, surgeryGuideStore } = useStores();

  useEffect(() => {
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideProcedures(surgeryGuideId);
    }
    return () => surgeryGuideStore.clearProcedures();
  }, [surgeryGuideStore, surgeryGuideStore.surgeryGuide, surgeryGuideId]);

  appNavigationStore.useSubPageIdSetter('procedures');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let procedureToBeMoved: SurgeryProcedureDTO;
    let newGuideProcedures = surgeryGuideStore.groupedGuideProcedures.map(guideProcedureGroup => {
      if (result.source?.droppableId === guideProcedureGroup.chapterId) {
        const draggableItem = guideProcedureGroup.surgeryGuideProcedures.find(p => p.surgeryGuideProcedureId === result.draggableId);
        procedureToBeMoved = draggableItem || procedureToBeMoved;
        return {
          ...guideProcedureGroup,
          surgeryGuideProcedures: guideProcedureGroup.surgeryGuideProcedures.filter(p => p.surgeryGuideProcedureId !== result.draggableId)
        };
      }
      return guideProcedureGroup;
    });
    newGuideProcedures = newGuideProcedures.map(guideProcedureGroup => {
      if (result.destination?.droppableId === guideProcedureGroup.chapterId) {
        guideProcedureGroup.surgeryGuideProcedures.splice(result.destination.index, 0, procedureToBeMoved);
        return {
          ...guideProcedureGroup,
          surgeryGuideProcedures: guideProcedureGroup.surgeryGuideProcedures
        };
      }
      return guideProcedureGroup;
    });
    const positions: UpdateSortSurgeryGuideProceduresDTO[] = newGuideProcedures.map(guideProcedureGroup => {
      const surgeryGuideProceduresIds = guideProcedureGroup.surgeryGuideProcedures.map(p => p.surgeryGuideProcedureId);
      return {
        chapterId: guideProcedureGroup.chapterId,
        surgeryGuideProceduresIds
      };
    });
    surgeryGuideStore.reorderProcedures(positions);
  };

  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SurgeryProceduresRightMenu />;
    });
  }, [appNavigationStore]);

  return (
    <>
      <div className="div-block-102_fixed in_proz_plan" />
      <div className="div-block-102_white in_proz_plan" />
      <div className="single_colum_content flex_width">
        <UsedGuide />
        <DragDropContext onDragEnd={onDragEnd}>
          {surgeryGuideStore.groupedGuideProcedures.map((procedureItem, index) => (
            <Droppable droppableId={procedureItem.chapterId} key={procedureItem.chapterId}>
              {provided => (
                <div ref={provided.innerRef}>
                  <div id={procedureItem.chapter}>
                    <div className={index > 0 ? 'h1 top-margin' : 'h1'}>{procedureItem.chapter}</div>
                    <SurgeryProceduresList
                      lazyLoadScrollContainer={lazyLoadScrollContainer}
                      chapterId={procedureItem.chapterId}
                      procedures={procedureItem.surgeryGuideProcedures}
                    />
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
    </>
  );
});

export default SurgeryProcedures;
