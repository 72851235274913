import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import CustomInput from 'components/CustomInput';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import HoverWrapper from 'components/HoverWrapper';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import { useTranslation } from 'react-i18next';
import { StorageItemDTO } from 'dto/storageItem';
import { getPackage } from 'api/package';
import './StorageLocationItem.css';

interface Props {
  storageItem: StorageItemDTO;
}

const StorageLocationItem = observer(({ storageItem }: Props) => {
  const { t } = useTranslation('storageLocations');
  const [materialAmount, setMaterialAmount] = useState<string | number>(storageItem.amount);
  const onSetMaterialAmount = (amount: string) => {
    if (+amount > 999) {
      setMaterialAmount(999);
      return;
    }
    if (amount !== '' && +amount < 1) {
      setMaterialAmount(1);
      return;
    }
    setMaterialAmount(amount !== '' ? +amount : '');
  };
  const { storageLocationStore, guideDetailDrawerStore } = useStores();
  const onInputBlur = () => {
    const item = {
      storageItemId: storageItem.storageItemId,
      amount: +materialAmount
    };
    storageLocationStore.updateStorageItem(item);
  };

  const deleteStorageItem = () => {
    if (
      (storageItem.material && storageLocationStore.materialLikeOrPackageId?.materialId === storageItem.material?.materialId) ||
      (storageItem.materialSet && storageLocationStore.materialLikeOrPackageId?.materialSetId === storageItem.materialSet?.materialSetId) ||
      (storageItem.package && storageLocationStore.materialLikeOrPackageId?.packageId === storageItem.package?.packageId)
    ) {
      storageLocationStore.deleteStorageItemByMaterialLikeId();
      storageLocationStore.setIsDetailDrawerOpen(false);
    } else {
      storageLocationStore.deleteStorageItem(storageItem.storageItemId);
    }
  };

  const onShowDetails = async () => {
    if (storageItem.material) {
      guideDetailDrawerStore.setSelectedMaterial(storageItem.material);
    }
    if (storageItem.materialSet) {
      guideDetailDrawerStore.setSelectedMaterialSet(storageItem.materialSet);
    }
    if (storageItem.package) {
      const fullPackage = await getPackage(storageItem.package.packageId);
      guideDetailDrawerStore.setSelectedPackage(fullPackage.pack);
    }
    guideDetailDrawerStore.setIsOpen(true);
  };

  const getPicture = (item: StorageItemDTO) => {
    if (item.material) {
      return item.material.pictureThumbnail;
    }
    if (item.materialSet) {
      return item.materialSet.pictureThumbnail;
    }
    if (item.package) {
      return item.package.picture?.pictureThumbnail;
    }
    return undefined;
  };
  const getName = (item: StorageItemDTO) => {
    if (item.material) {
      return item.material.name;
    }
    if (item.materialSet) {
      return item.materialSet.name;
    }
    if (item.package) {
      return item.package.name;
    }
    return null;
  };
  const picture = getPicture(storageItem);
  return (
    <HoverWrapper className="list-item list_item_material margin-right storage-item-container">
      {({ hover }) => (
        <>
          <CustomInput
            value={materialAmount.toString()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSetMaterialAmount(event.target.value)}
            containerClassName="input_field_settings-copy-copy storage-item-input"
            placeholder=""
            onBlur={onInputBlur}
            noMarginTop
            type="number"
            isSmallInput
            min={1}
            max={999}
          />
          <div className="image_wrapper_50" onClick={onShowDetails}>
            <div className="image_border image-border" />
            <Picture src={picture} width={50} alt="" className="image_circle_50" />
          </div>
          <div className="material_info">
            <div className="material_text" onClick={onShowDetails}>
              <div>{getName(storageItem)}</div>
            </div>
            <MaterialValueTags
              material={storageItem.material}
              materialSet={storageItem.materialSet}
              simplePack={storageItem.package}
              onClick={onShowDetails}
              fullInfo={!storageItem.material}
            />
            <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
              {({ handleClose, isOpen }) => (
                <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
                  <ItemMenuButton
                    warning
                    label={t('button.deleteStorageItem')}
                    icon="icon_bin_16.svg"
                    isInPopover
                    handleClick={deleteStorageItem}
                  />
                </ItemMenu>
              )}
            </Popover>
          </div>
        </>
      )}
    </HoverWrapper>
  );
});

export default StorageLocationItem;
