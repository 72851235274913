import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { DepartmentMaterialSetsDTO, MaterialSetDTO, SetType } from 'dto/materialSet';
import ScrollPositionManager from 'components/ScrollPositionManager';
import { useParams } from 'react-router-dom';
import DepartmentLead from 'components/DepartmentLead';
import { getKeyByValue } from 'util/enum';
import GroupsList from './GroupsList';
import GroupsRightMenu from './GroupsRightMenu';
import CreateMaterialSetFlyout from './CreateMaterialSetFlyout';
import FiltersByDepartment from './FiltersByDepartment';

import './Groups.css';

interface MatchParams {
  groupType: string;
}

const filterGroups = (selectedDepartmentId: string, groupedMaterialSets: DepartmentMaterialSetsDTO[]) => {
  if (selectedDepartmentId === 'all') {
    let materialSets: MaterialSetDTO[] = [];
    groupedMaterialSets.forEach(groupedMaterialSet => {
      materialSets = [...materialSets, ...groupedMaterialSet.materialSets];
    });
    return materialSets;
  }
  return groupedMaterialSets.find(groupedMaterialSet => groupedMaterialSet.departmentId === selectedDepartmentId)?.materialSets || [];
};

const Groups = observer(() => {
  const { groupType: groupTypeString } = useParams<MatchParams>();
  const { appNavigationStore, domainStore, materialSetsStore, loadingStore } = useStores();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('all');
  const groupType = getKeyByValue(SetType, groupTypeString);

  useEffect(() => {
    if (domainStore.departmentsByCurrentFunctionArea?.functionalAreaId) {
      materialSetsStore.loadMaterialSets(domainStore.departmentsByCurrentFunctionArea.functionalAreaId, groupType);
    }
    return () => {
      materialSetsStore.clearMaterialSets();
    };
  }, [materialSetsStore, domainStore.departmentsByCurrentFunctionArea, groupType]);
  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <GroupsRightMenu groupType={groupTypeString} key={groupTypeString} />;
    });
  }, [appNavigationStore, groupTypeString]);
  const onSelectDepartment = (id: string) => setSelectedDepartmentId(id);
  const groups = filterGroups(selectedDepartmentId, materialSetsStore.groupedMaterialSets);
  return (
    <div className="w-layout-grid grid_colum_content pb-80-desktop">
      <div id="grid-guides-colum_left" className="colum_left">
        <DepartmentLead />
        <div className="line" />
        <FiltersByDepartment
          onSelect={onSelectDepartment}
          selectedDepartmentId={selectedDepartmentId}
          groupType={groupTypeString}
          key={groupTypeString}
        />
      </div>

      {!loadingStore.isLoading && (
        <ScrollPositionManager
          scrollKey="grid-guides-colum-right"
          id="grid-guides-colum_right"
          className="colum_right materials colum-scroll mobile-overflow-x"
          style={{ gridColumnStart: '2' }}
        >
          <GroupsList groups={groups} groupType={groupTypeString} />
        </ScrollPositionManager>
      )}

      <CreateMaterialSetFlyout groupType={groupType} />
    </div>
  );
});

export default Groups;
