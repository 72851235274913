import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import { animateScroll } from 'react-scroll';
import DialogForm from './DialogForm';
import DialogPost from './DialogPost';

import './Dialog.css';

const Dialog = observer(() => {
  const { t } = useTranslation('board');
  const { postStore, domainStore } = useStores();

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({ containerId: 'dialog-posts-container', duration: 1 });
  };

  useEffect(() => {
    postStore.loadNextFunctionalAreaPosts(domainStore.currentFunctionalArea.id);
  }, [postStore, domainStore.currentFunctionalArea.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      postStore.loadNewFunctionalAreaPosts(domainStore.currentFunctionalArea.id);
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [domainStore.currentFunctionalArea, postStore]);

  if (!postStore.functionalAreaPosts) {
    return <> </>;
  }
  return (
    <div className="chat-wrapper chat-wrapper-custom">
      <div className="board_header">
        <img src="images/icon_feedback_chat_24.svg" alt="" className="board_icon_chatbox" />
        <div>{t('label.dialog')}</div>
      </div>
      {/* Thanks to CSS Styling column-reverse the list is being reversed */}
      <div id="dialog-posts-container" className="chat_inner_wrapper chat-inner-wrapper">
        {postStore.newFunctionalAreaPosts.map(post => {
          return <DialogPost post={post} key={post.postId} />;
        })}
        <InfiniteScroll
          className="infinite-scroll-container"
          hasMore={postStore.functionalAreaPosts.count > postStore.functionalAreaPosts.posts.length}
          dataLength={postStore.functionalAreaPosts.posts.length}
          next={() => {
            postStore.loadNextFunctionalAreaPosts(domainStore.currentFunctionalArea.id);
          }}
          loader={<> </>}
          scrollableTarget="dialog-posts-container"
          inverse
        >
          {postStore.functionalAreaPosts.posts.map(post => (
            <DialogPost post={post} key={post.postId} />
          ))}
        </InfiniteScroll>
      </div>
      <DialogForm afterSubmit={scrollToBottom} />
    </div>
  );
});

export default Dialog;
