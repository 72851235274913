import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import MaterialDrawerDetailNav from 'components/MaterialDrawerDetailNav';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { PackagingType, PackageDTO } from 'dto/package';
import { CSSTransition } from 'react-transition-group';
import { Can, subjectArea } from 'casl/setupCaslAbility';
import Button from 'components/Form/Button';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { subject } from '@casl/ability';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import GuideSettings from '../GuideSettings';
import FixedTopPackageBox from './FixedTopPackageBox';
import PackageHead from './PackageHead';
import DrawerPackageContent from './DrawerPackageContent';

interface Props {
  packageItem: PackageDTO;
  guideTemplate?: GuideMaterialLikeDTO;
  surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO;
  onUpdateGuideMaterial: (guideMaterial?: GuideMaterialLikeDTO, surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO) => void;
}

const PackageDrawerContent = observer(({ packageItem, guideTemplate, surgeryGuideMaterial, onUpdateGuideMaterial }: Props) => {
  const { guideStore, instrumentStore, guideDetailDrawerStore, procedureStore, searchStore, materialStore, domainStore } = useStores();
  const [isShow, setIsShow] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(window.innerWidth >= 991);
  const { t } = useTranslation('guideSettings');

  const handleContentWrapperScroll = (event: React.UIEvent<HTMLElement>) => {
    if (!isShow && event.currentTarget.scrollTop >= 300) {
      setIsShow(true);
    } else if (isShow && event.currentTarget.scrollTop <= 299 && event.currentTarget.scrollTop !== 0) {
      setIsShow(false);
    }
  };

  let onUpdateFile = async (fileId: string) => {
    if (!packageItem.pictures) {
      return;
    }

    const pictureFileIds = packageItem.pictures.map((file, index) => {
      if (index === 0) {
        return fileId;
      }
      return file.pictureFileId;
    });
    if (pictureFileIds.length === 0) {
      pictureFileIds.push(fileId);
    }
    await instrumentStore.updatePackage({ pictureFileIds, packageId: packageItem.packageId }, false);
    if (guideStore.selectedGuide) {
      procedureStore.loadProcedures(guideStore.selectedGuide.guideId);
    }
    if (searchStore.isSearchPanelOpen) {
      searchStore.refreshSearch();
    }
    guideDetailDrawerStore.reloadSelectedPackage();
  };

  if (packageItem && packageItem.packagingType === PackagingType.Single) {
    onUpdateFile = async (fileId: string) => {
      if (!packageItem) {
        return;
      }
      await materialStore.updateMaterial(
        {
          materialId: packageItem.instruments[0].material.materialId,
          pictureFileId: fileId
        },
        false
      );
      guideDetailDrawerStore.reloadSelectedMaterial();
      guideDetailDrawerStore.reloadSelectedPackage();
      if (guideStore.selectedGuide) {
        procedureStore.loadProcedures(guideStore.selectedGuide.guideId);
      }
      if (searchStore.isSearchPanelOpen) {
        searchStore.refreshSearch();
      }
    };
  }

  return (
    <>
      <div className="div-block-14">
        <div className="div-block-12">
          <div className="ios-bar" />
          <CreateMaterialSynonymFlyout
            isOpen={guideDetailDrawerStore.isCreateMaterialSynonymOpen}
            onClose={() => guideDetailDrawerStore.closeCreateMaterialSynonymFlyout()}
          />
          <div className="mat_content_wrapper" onScroll={handleContentWrapperScroll}>
            <FixedTopPackageBox packageItem={packageItem} isShow={isShow} onUpdateFile={onUpdateFile} />
            <div className="div-block-21" onScroll={handleContentWrapperScroll}>
              <PackageHead
                packageItem={packageItem}
                singleInstrument={packageItem.packagingType === PackagingType.Single ? packageItem.instruments[0].material : undefined}
                onUpdateFile={onUpdateFile}
              />
              <DrawerPackageContent key={packageItem.packageId} packageItem={packageItem} />
            </div>
          </div>
          {guideDetailDrawerStore.settingsEnabled && guideTemplate && (
            <Can I="see" this={subject(subjectArea.materialSettings, { departmentId: domainStore.currentDepartment.id })}>
              <CSSTransition in={isSettingsOpen} timeout={500} classNames="settings-drawer" unmountOnExit>
                <GuideSettings
                  guideMaterial={guideTemplate}
                  surgeryGuideMaterial={surgeryGuideMaterial}
                  onSettingsClose={() => setIsSettingsOpen(false)}
                  hideStorageLocation
                />
              </CSSTransition>
            </Can>
          )}
          {guideDetailDrawerStore.settingsEnabled && surgeryGuideMaterial && (
            <CSSTransition in={isSettingsOpen} timeout={500} classNames="settings-drawer" unmountOnExit>
              <GuideSettings
                guideMaterial={guideTemplate}
                surgeryGuideMaterial={surgeryGuideMaterial}
                onSettingsClose={() => setIsSettingsOpen(false)}
                hideStorageLocation
              />
            </CSSTransition>
          )}
        </div>

        <div className="div-block-13">
          <MaterialDrawerDetailNav
            detailLink={
              packageItem.packagingType === PackagingType.Single
                ? `/single-instrument/overview?packageId=${packageItem.packageId}`
                : `/package/overview?packageId=${packageItem.packageId}`
            }
            knowledgeLink={
              packageItem.packagingType === PackagingType.Single
                ? `/single-instrument/content?packageId=${packageItem.packageId}`
                : `/package/content?packageId=${packageItem.packageId}`
            }
            onShowSettings={() => setIsSettingsOpen(true)}
            onUpdateFile={onUpdateFile}
            isGuideMaterial={!!guideTemplate}
            isSurgeryGuideMaterial={!!surgeryGuideMaterial}
          />
          <Button onClick={() => onUpdateGuideMaterial(guideTemplate, surgeryGuideMaterial)} className="btn_big_cancel active">
            <div>{t('button.close')}</div>
          </Button>
        </div>
      </div>
    </>
  );
});

export default PackageDrawerContent;
