import React from 'react';
import { MaterialSetDTO, SetType } from 'dto/materialSet';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import HoverWrapper from 'components/HoverWrapper';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import Instructions from 'components/ListItems/components/Instructions';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import { useStores } from 'util/mobx/stores';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryMaterialSetListItemMenu from './SurgeryMaterialSetListItemMenu';
import SurgerySetGroupListItem from '../SurgerySetGroupListItem';
import SurgeryMaterialCheckItem from '../SurgeryMaterialCheckItem';

import './SurgeryMaterialSetListItem.css';

interface Props extends OptionalLazyLoadProps {
  materialSet: MaterialSetDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  dropdownContainerIndex: number;
}

const SurgeryMaterialSetListItem = ({ materialSet, surgeryGuide, dropdownContainerIndex, lazyLoadScrollContainer }: Props) => {
  const { guideDetailDrawerStore, surgeryGuideStore, materialSetsStore } = useStores();

  const onOpenDetails = () => {
    guideDetailDrawerStore.setSelectedMaterialSet(materialSet);
    guideDetailDrawerStore.setSelectedSurgeryGuideItem(surgeryGuide);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const usedMaterial = surgeryGuide.usedMaterials.find(
    m => m.surgeryGuideMaterialId === surgeryGuide.surgeryGuideMaterialId && !m.groupMaterialId
  );

  const onStatusChange = (status: UsedMaterialStatus) => {
    surgeryGuideStore.setUsedMaterial({
      surgeryGuideMaterialId: surgeryGuide.surgeryGuideMaterialId,
      status
    });
  };

  const onUpdateFile = async (fileId: string) => {
    await materialSetsStore.updateMaterialSet({ materialSetId: materialSet.materialSetId, pictureFileId: fileId });
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideStore.surgeryGuide.surgeryGuideId);
    }
  };

  const renderList = () => (
    <div className="content_holder no_padding padding_left set-group-list" style={{ zIndex: dropdownContainerIndex }}>
      {materialSet.setGroups.map((setGroup, index) => {
        return (
          <SurgerySetGroupListItem
            lazyLoadScrollContainer={lazyLoadScrollContainer}
            setGroup={setGroup}
            key={setGroup.setGroupId}
            surgeryGuide={surgeryGuide}
            dropdownContainerIndex={dropdownContainerIndex - (index + 1)}
          />
        );
      })}
    </div>
  );

  return (
    <ListItemDropdownContainer
      ListElement={renderList}
      classPrefix="material-set"
      className="surgery-material-set"
      style={{ zIndex: dropdownContainerIndex }}
    >
      <HoverWrapper className="list_item_material with_dropdown in_materiallist package-info">
        {({ hover }) => (
          <>
            <div className="item_count _1_lvl" onClick={onOpenDetails}>
              <div>{surgeryGuide.amount}</div>
            </div>
            <div className="item_dropdown_holder" onClick={onOpenDetails} />
            <div className="image_wrapper_50">
              <div className="image_border image-border" />
              {!surgeryGuide.storageLocationId && surgeryGuide.materialSet?.type !== SetType.Medicals && (
                <div className="icon_no_location">
                  <img src="images/icon_orange_flag.jpg" alt="" />
                </div>
              )}
              {materialSet.picture ? (
                <Picture
                  withBorder
                  src={materialSet}
                  size="pictureThumbnail"
                  width={50}
                  alt=""
                  lazyLoadScrollContainer={lazyLoadScrollContainer}
                  className="image_circle_50"
                />
              ) : (
                <GetCameraImageButton
                  buttonStyle="icon"
                  description=""
                  onUpdateFile={onUpdateFile}
                  isAddable
                  hover={hover}
                  withLoadingBar
                />
              )}
              <SurgeryMaterialCheckItem
                onStatusChanged={onStatusChange}
                status={usedMaterial?.status}
                surgeryGuide={surgeryGuide}
                usedMaterial={usedMaterial}
              />
            </div>
            <div className="material_info">
              <div className="material_text" onClick={onOpenDetails}>
                <div>{materialSet.name}</div>
                <Instructions notes={surgeryGuide.notes} translationPrefix="materialSet" />
              </div>
              <MaterialValueTags materialSet={materialSet} fullInfo onClick={onOpenDetails} />
              <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                {({ handleClose, isOpen }) => (
                  <SurgeryMaterialSetListItemMenu
                    handleClose={handleClose}
                    isOpen={!!isOpen}
                    surgeryGuide={surgeryGuide}
                    usedMaterial={usedMaterial}
                    onOpenDetails={onOpenDetails}
                  />
                )}
              </Popover>
            </div>
          </>
        )}
      </HoverWrapper>
    </ListItemDropdownContainer>
  );
};

export default SurgeryMaterialSetListItem;
