import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { subject } from '@casl/ability';
import { AbilityContext, actions, subjectArea } from 'casl/setupCaslAbility';
import { useAbility } from '@casl/react';
import MaterialDrawerContent from './MaterialDrawerContent';
import PackageDrawerContent from './PackageDrawerContent';
import InstrumentDrawerContent from './InstrumentDrawerContent';
import MaterialSetDrawerContent from './MaterialSetDrawerContent';
import './GuideDetailDrawer.css';

const GuideDetailDrawer = observer(() => {
  const { guideDetailDrawerStore, guideStore, surgeryGuideStore, domainStore } = useStores();

  useEffect(() => {
    const { selectedGuideItem, selectedSurgeryGuideItem, selectedPackage, selectedMaterial, selectedMaterialSet } = guideDetailDrawerStore;

    if (selectedGuideItem?.storageLocationId) {
      guideDetailDrawerStore.loadStorageLocationPath(selectedGuideItem.storageLocationId);
    }
    if (selectedSurgeryGuideItem?.storageLocationId) {
      guideDetailDrawerStore.loadStorageLocationPath(selectedSurgeryGuideItem.storageLocationId);
    }
    if (selectedMaterial) {
      guideDetailDrawerStore.loadMaterialStorageLocationPaths({ materialId: selectedMaterial.materialId });
    }
    if (selectedMaterialSet) {
      guideDetailDrawerStore.loadMaterialStorageLocationPaths({ materialSetId: selectedMaterialSet.materialSetId });
    }
    if (selectedPackage) {
      guideDetailDrawerStore.loadMaterialStorageLocationPaths({ packageId: selectedPackage.packageId });
    }
    return () => guideDetailDrawerStore.resetStorageLocationData();
  }, [
    guideDetailDrawerStore,
    guideDetailDrawerStore.selectedGuideItem,
    guideDetailDrawerStore.selectedMaterial,
    guideDetailDrawerStore.selectedMaterialSet,
    guideDetailDrawerStore.selectedPackage,
    guideStore.selectedGuide,
    domainStore.currentFunctionalArea
  ]);

  const ability = useAbility(AbilityContext);

  const onUpdateGuideMaterial = (guideMaterial?: GuideMaterialLikeDTO, surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO) => {
    if (
      guideMaterial &&
      ability.can(actions.update, subject(subjectArea.guideMaterial, { departmentId: domainStore.currentDepartment.id }))
    ) {
      guideDetailDrawerStore.updateGuideMaterial(
        {
          guideMaterialId: guideMaterial.guideMaterialId,
          ...guideDetailDrawerStore.guideSettingsValues
        },
        () => guideStore.refreshGuideMaterials()
      );
    }
    if (surgeryGuideMaterial) {
      guideDetailDrawerStore.updateSurgeryGuideMaterial(
        {
          surgeryGuideMaterialId: surgeryGuideMaterial.surgeryGuideMaterialId,
          ...guideDetailDrawerStore.guideSettingsValues
        },
        () => surgeryGuideStore.refreshGuideMaterials()
      );
    }
    guideDetailDrawerStore.setIsOpen(false);
  };

  return (
    <CSSTransition in={guideDetailDrawerStore.isOpen} timeout={500} classNames="drawer" unmountOnExit>
      <div className="sempermed behind-flylout">
        {guideDetailDrawerStore.selectedMaterial && (
          <MaterialDrawerContent
            material={guideDetailDrawerStore.selectedMaterial}
            guideMaterial={guideDetailDrawerStore.selectedGuideItem}
            surgeryGuideMaterial={guideDetailDrawerStore.selectedSurgeryGuideItem}
            onUpdateGuideMaterial={onUpdateGuideMaterial}
          />
        )}
        {guideDetailDrawerStore.selectedPackage && (
          <PackageDrawerContent
            packageItem={guideDetailDrawerStore.selectedPackage}
            guideTemplate={guideDetailDrawerStore.selectedGuideItem}
            surgeryGuideMaterial={guideDetailDrawerStore.selectedSurgeryGuideItem}
            onUpdateGuideMaterial={onUpdateGuideMaterial}
          />
        )}
        {guideDetailDrawerStore.selectedInstrument && (
          <InstrumentDrawerContent
            instrument={guideDetailDrawerStore.selectedInstrument}
            guideTemplate={guideDetailDrawerStore.selectedGuideItem}
            surgeryGuideMaterial={guideDetailDrawerStore.selectedSurgeryGuideItem}
            onUpdateGuideMaterial={onUpdateGuideMaterial}
          />
        )}
        {guideDetailDrawerStore.selectedMaterialSet && (
          <MaterialSetDrawerContent
            materialSet={guideDetailDrawerStore.selectedMaterialSet}
            guideMaterial={guideDetailDrawerStore.selectedGuideItem}
            surgeryGuideMaterial={guideDetailDrawerStore.selectedSurgeryGuideItem}
            onUpdateGuideMaterial={onUpdateGuideMaterial}
          />
        )}
      </div>
    </CSSTransition>
  );
});

export default GuideDetailDrawer;
