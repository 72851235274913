import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { StorageLocationListItem } from 'components/ListItems';
import { SimpleStorageLocationDTO } from 'dto/storageLocation';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { getItemStyle } from 'util/dragAndDrop';

interface Props {
  index: number;
  hideAddButtons?: boolean;
  whiteBorder?: boolean;
}

const ListByIndex = observer(({ index, hideAddButtons = false, whiteBorder = false }: Props) => {
  const { storageLocationStore } = useStores();
  const { t } = useTranslation('storageLocations');
  if (!storageLocationStore.storageLocationsBuffer[index]) {
    return null;
  }
  const handleCreateLocation = () => {
    storageLocationStore.setSelectedParentId(storageLocationStore.storageLocationsBuffer[index].parentStorageLocationId);
    storageLocationStore.setSelectedIndexToCreate(index);
    storageLocationStore.setIsCreateFlyoutOpen(true);
  };

  const checkActive = (location: SimpleStorageLocationDTO) => {
    if (location.storageLocationId === storageLocationStore.selectedStorageLocation?.parentStorageLocationId) {
      return true;
    }
    if (location.storageLocationId === storageLocationStore.selectedStorageLocation?.storageLocationId) {
      return true;
    }
    return false;
  };

  const selectStorageLocation = (storageLocation: SimpleStorageLocationDTO) => {
    if (storageLocationStore.selectedStorageLocation?.storageLocationId === storageLocation.storageLocationId) {
      storageLocationStore.goToPreviousIndex();
      return;
    }
    storageLocationStore.setSelectedStorageLocation(storageLocation, index);
  };

  const getHeaderByIndex = () => {
    if (index === 0) {
      return (
        <>
          <div className="h3">{t('startingPoint')}</div>
          {!hideAddButtons && (
            <ListItemAdd className="post_item_add in_list lageort" onClick={handleCreateLocation}>
              <InfoBox label={t('button.createStartPoint')} />
            </ListItemAdd>
          )}
        </>
      );
    }
    return (
      <>
        <div className="h3">{t('startingPointLocation')}</div>
        {!hideAddButtons && (
          <ListItemAdd className="post_item_add in_list lageort" onClick={handleCreateLocation}>
            <InfoBox label={t('button.createStartPointLocation')} />
          </ListItemAdd>
        )}
      </>
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const storageLocationToBeMoved = storageLocationStore.storageLocationsBuffer[index].storageLocations.find(
      s => s.storageLocationId === result.draggableId
    );
    const storageLocations = storageLocationStore.storageLocationsBuffer[index].storageLocations.filter(
      s => s.storageLocationId !== result.draggableId
    );

    if (storageLocationToBeMoved) {
      storageLocations.splice(result.destination.index, 0, storageLocationToBeMoved);
      storageLocationStore.reorderStorageLocations(storageLocations, index);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="div-block-140">
        {dropProvider => (
          <div className="div-block-140" {...dropProvider.droppableProps} ref={dropProvider.innerRef}>
            {getHeaderByIndex()}
            <div className={`storage-location-items-list ${storageLocationStore.selectedStorageLocation ? 'with-back-button' : ''}`}>
              {storageLocationStore.storageLocationsBuffer[index].storageLocations.map((storageLocation, i) => (
                <Draggable key={storageLocation.storageLocationId} draggableId={storageLocation.storageLocationId} index={i}>
                  {(dragProvider, snapshot) => (
                    <div
                      ref={dragProvider.innerRef}
                      {...dragProvider.draggableProps}
                      {...dragProvider.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, dragProvider.draggableProps.style)}
                    >
                      <StorageLocationListItem
                        storageLocation={storageLocation}
                        key={storageLocation.storageLocationId}
                        isSelected={checkActive(storageLocation)}
                        onClick={() => selectStorageLocation(storageLocation)}
                        whiteBorder={whiteBorder}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default ListByIndex;
