import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import UsedGuide from 'pages/surgeryProcedures/UsedGuide';
import { GroupedSurgeryGuideMaterialsDTO, SurgeryGuideMaterialLikeDTO, UpdateSortSurgeryGuideMaterialsDTO } from 'dto/surgeryGuide';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryMaterialsList from './SurgeryMaterialsList';
import SurgeryMaterialsRightMenu from './SurgeryMaterialsRightMenu';

import './SurgeryMaterials.css';

interface Props extends OptionalLazyLoadProps {
  surgeryGuideId: string;
}

const SurgeryMaterials = observer(({ surgeryGuideId, lazyLoadScrollContainer }: Props) => {
  const { appNavigationStore, surgeryGuideStore, guideDetailDrawerStore, loadingStore } = useStores();

  useEffect(() => {
    guideDetailDrawerStore.enableSettings();
    return () => {
      guideDetailDrawerStore.disableSettings();
    };
  });

  useEffect(() => {
    if (surgeryGuideId) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideId);
    }
    return () => surgeryGuideStore.clearMaterials();
  }, [surgeryGuideStore, surgeryGuideId]);

  appNavigationStore.useSubPageIdSetter('materials');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let materialToBeMoved: SurgeryGuideMaterialLikeDTO;
    let newGuideMaterials = surgeryGuideStore.guideMaterials.map(guideMaterialGroup => {
      if (result.source?.droppableId === guideMaterialGroup.categoryId) {
        const draggableItem = guideMaterialGroup.materials.find(p => p.surgeryGuideMaterialId === result.draggableId);
        materialToBeMoved = draggableItem || materialToBeMoved;
        return {
          ...guideMaterialGroup,
          materials: guideMaterialGroup.materials.filter(p => p.surgeryGuideMaterialId !== result.draggableId)
        };
      }
      return guideMaterialGroup;
    });
    newGuideMaterials = newGuideMaterials.map(guideMaterialGroup => {
      if (result.destination?.droppableId === guideMaterialGroup.categoryId) {
        guideMaterialGroup.materials.splice(result.destination.index, 0, materialToBeMoved);
        return {
          ...guideMaterialGroup,
          materials: guideMaterialGroup.materials
        };
      }
      return guideMaterialGroup;
    });
    const positions: UpdateSortSurgeryGuideMaterialsDTO[] = newGuideMaterials.map(guideMaterial => {
      const surgeryGuideMaterialIds = guideMaterial.materials.map(m => m.surgeryGuideMaterialId);
      return {
        categoryId: guideMaterial.categoryId,
        surgeryGuideMaterialIds
      };
    });
    surgeryGuideStore.reorderMaterials(positions);
  };

  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SurgeryMaterialsRightMenu />;
    });
  }, [appNavigationStore]);

  const [renderedGuideMaterials, setRenderedGuideMaterials] = useState<JSX.Element[]>([]);

  useEffect(() => {
    async function renderGuideMaterials(guideMaterials: GroupedSurgeryGuideMaterialsDTO[]) {
      return guideMaterials.map((guideMaterialItem, index) => (
        <Droppable droppableId={guideMaterialItem.categoryId} key={guideMaterialItem.categoryId}>
          {provided => (
            <div ref={provided.innerRef} className="single-colum-content-item">
              <div id={guideMaterialItem.category}>
                <div className={index > 0 ? 'h1 top-margin' : 'h1'}>{guideMaterialItem.category}</div>
                <SurgeryMaterialsList
                  lazyLoadScrollContainer={lazyLoadScrollContainer}
                  categoryId={guideMaterialItem.categoryId}
                  materials={guideMaterialItem.materials}
                  categoryIndex={index}
                />
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      ));
    }

    loadingStore.withOnlyLoadingBar(async () => {
      await renderGuideMaterials(surgeryGuideStore.guideMaterials).then(materials => {
        setRenderedGuideMaterials(materials);
      });
    });

    return () => {
      setRenderedGuideMaterials([]);
    };
  }, [loadingStore, surgeryGuideStore.guideMaterials, lazyLoadScrollContainer]);

  return (
    <>
      <div className="div-block-102_fixed" />
      <div className="div-block-102_white white-in-material-list" />
      <div className="single_colum_content flex_width">
        <UsedGuide inMaterials />
        <DragDropContext onDragEnd={onDragEnd}>{renderedGuideMaterials}</DragDropContext>
      </div>
    </>
  );
});

export default SurgeryMaterials;
