import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onOpenDetails: () => void;
}

const SurgeryInstrumentListItemMenu = ({ isOpen, handleClose, onOpenDetails }: Props) => {
  const { t } = useTranslation('briefing');

  return (
    <>
      <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
        <ItemMenuButton
          label={t('briefingMaterials.surgeryMaterialItem.menu.openDetails')}
          icon="icon_search-content_16.svg"
          isInPopover
          handleClick={onOpenDetails}
        />
      </ItemMenu>
    </>
  );
};

export default SurgeryInstrumentListItemMenu;
