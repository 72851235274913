import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Guides from 'pages/guides';
import Guide from 'pages/guide';
import Material from 'pages/material';
import Package from 'pages/package';
import Instrument from 'pages/instrument';
import SingleInstrument from 'pages/singleInstrument';
import MaterialSet from 'pages/materialSet';
import Settings from 'pages/settings';
import LocationAdministration from 'pages/locationAdministration';
import {
  lockPortrait,
  addKeyboardListeners,
  addNetworkListeners,
  addAppListenerToCheckIfActive,
  getNetworkStatus,
  getApiUrl,
  setApiUrl
} from 'util/mobile/mobileUtils';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import WifiIndicator from 'components/WifiIndicator';
import AccountManagement from 'pages/accountManagement';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Surgery from 'pages/surgery';
import SurgeryGuide from 'pages/surgeryGuide';
import Authentication from 'pages/authentication/Authentication';
import UserSettings from 'pages/userSettings';
import ErrorAlert from 'components/ErrorAlert';
import config from 'config/config';

const App = (): JSX.Element => {
  const { t } = useTranslation('app');
  const [isWifi, setIsWifi] = useState<boolean | undefined>();
  const [needApiURL, setNeedApiURL] = useState<boolean>();
  const [apiUrl, setApiURL] = useState('');
  const { domainStore, authStore, appNavigationStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    lockPortrait();
    addNetworkListeners(status => {
      setIsWifi(status.connected);
    });
    if (isWifi === undefined) {
      const netWorkStatus = getNetworkStatus();
      netWorkStatus.then(nS => setIsWifi(nS?.connected));
    }
    addAppListenerToCheckIfActive(() => authStore.logout());

    addKeyboardListeners(
      () => {
        appNavigationStore.setIsKeyboardOpen(true);
      },
      () => {
        appNavigationStore.setIsKeyboardOpen(false);
      }
    );
  }, [appNavigationStore, authStore, isWifi]);

  useEffect(() => {
    const fetchApiUrl = async () => {
      if (config.apiPath) {
        appNavigationStore.apiReady();
        setNeedApiURL(false);
        return;
      }
      const apiUrlFromStorage = await getApiUrl();
      if (apiUrlFromStorage.value) {
        config.apiPath = apiUrlFromStorage.value;
        appNavigationStore.apiReady();
        setNeedApiURL(false);
        return;
      }

      setNeedApiURL(true);
    };
    fetchApiUrl();
  }, [appNavigationStore, t]);

  history.listen((location, action) => {
    // ignore login route - prevent saving login on browser refresh in develop
    if (location.pathname === '/' && action === 'PUSH') {
      return;
    }
    if (appNavigationStore.path && appNavigationStore.path.current === location.pathname) {
      return;
    }

    if (appNavigationStore.path) {
      appNavigationStore.setPath({
        prev: appNavigationStore.path.current,
        current: location.pathname
      });
    } else {
      appNavigationStore.setPath({
        prev: null,
        current: location.pathname
      });
    }
  });

  // Wait till we show the api-url input or the config is loaded.
  if (needApiURL === undefined) {
    return <></>;
  }

  // We need to show a small form to get the api url.
  if (needApiURL === true) {
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          if (apiUrl) {
            config.apiPath = apiUrl;
            setApiUrl(apiUrl);
            appNavigationStore.apiReady();
            setNeedApiURL(false);
          }
        }}
      >
        <div style={{ marginTop: '15rem', textAlign: 'center' }}>
          <label htmlFor="apiURL">API URL: </label>
          <input type="text" name="apiURL" id="apiURL" value={apiUrl} onChange={v => setApiURL(v.target.value)} />
          <input type="submit" value="Speichern" />
        </div>
      </form>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Switch>
        <Route exact path="/" component={Authentication} />
        <Route path="/guides" component={Guides} />
        <Route path="/guide" component={Guide} />
        <Route path="/material" component={Material} />
        <Route path="/package" component={Package} />
        <Route path="/instrument" component={Instrument} />
        <Route path="/single-instrument" component={SingleInstrument} />
        <Route path="/group/:groupType" component={MaterialSet} />
        <Route path="/accountManagement" component={AccountManagement} />
        <Route path="/settings" component={Settings} />
        <Route path="/user-settings" component={UserSettings} />
        <Route path="/administration/location" component={LocationAdministration} />
        <Route path="/surgery" component={Surgery} />
        <Route path="/surgery-guide" component={SurgeryGuide} />
      </Switch>
      <ErrorAlert />
      {domainStore.isMobile ? <WifiIndicator isWifi={isWifi} /> : <></>}
    </MuiPickersUtilsProvider>
  );
};

export default App;
