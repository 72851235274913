/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { ValidateNested, IsOptional, IsEnum, IsDate, ValidateIf, IsUUID, IsInt, IsNumber } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { ContentElementDTO, CreatePostContentElementDTO } from './contentElement';
import { ProcedureDTO } from './procedure';
import { FullGuideDTO } from './guide';
import { DepartmentDTO } from './department';
import { PagingDTO } from './generic';

export enum PostType {
  Chat, // if the contentElementId is filled in the DB
  ProcedureUpdate // if guideProcedureId is filled in the db
}

export class PostDTO {
  @IsUUID()
  postId!: string;

  @IsEnum(PostType)
  type!: PostType;

  /**
   * as UTC
   */
  @IsDate()
  @Type(() => Date)
  createdAt!: Date;

  // Content
  @ValidateNested()
  @Type(() => ContentElementDTO)
  contentElement!: ContentElementDTO;

  // add the full guideProcedure if the type is procedureUpdate
  @ValidateIf(o => o.type === PostType.ProcedureUpdate)
  @ValidateNested()
  @Type(() => ProcedureDTO)
  guideProcedure?: ProcedureDTO;

  // destination information
  // only one of them should be filled to determine which it is
  @ValidateNested()
  @Type(() => FullGuideDTO)
  @IsOptional()
  guide?: FullGuideDTO;

  @ValidateNested()
  @Type(() => DepartmentDTO)
  @IsOptional()
  department?: DepartmentDTO;
}

export class PostListDTO {
  @IsNumber()
  count!: number;

  @ValidateNested({ each: true })
  @Type(() => PostDTO)
  posts!: PostDTO[];
}
export class CreateGuidePostDTO {
  // Content
  @ValidateNested()
  @Type(() => CreatePostContentElementDTO)
  content!: CreatePostContentElementDTO;

  // destination information
  @IsUUID()
  guideId!: string;
}

export class CreateDepartmentPostDTO {
  // Content
  @ValidateNested()
  @Type(() => CreatePostContentElementDTO)
  content!: CreatePostContentElementDTO;

  // destination information
  @IsUUID()
  departmentId!: string;
}

export class CreateFunctionalAreaPostDTO {
  // Content
  @ValidateNested()
  @Type(() => CreatePostContentElementDTO)
  content!: CreatePostContentElementDTO;

  @IsUUID()
  functionalAreaId!: string;
}

export class CreateBriefingPostDTO {
  // Content
  @ValidateNested()
  @Type(() => CreatePostContentElementDTO)
  content!: CreatePostContentElementDTO;

  @IsUUID()
  briefingId!: string;
}

export class GetPostDTO extends PagingDTO {
  @IsUUID()
  @IsOptional()
  guideId?: string;

  @IsOptional()
  @IsUUID()
  functionalAreaId?: string;

  @IsInt()
  @IsOptional()
  days?: number;
}

export class GetDepartmentPostDTO extends PagingDTO {
  @IsUUID()
  @IsOptional()
  departmentId?: string;

  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsInt()
  @IsOptional()
  days?: number;
}

export class GetFunctionalAreaPostDTO extends PagingDTO {
  @IsUUID()
  functionalAreaId!: string;

  @IsUUID()
  @IsOptional()
  latestFunctionalAreaPostId?: string;

  @IsInt()
  @IsOptional()
  days?: number;
}

export class GetNewFunctionalAreaPostsDTO {
  @IsUUID()
  functionalAreaId!: string;

  @IsUUID()
  @IsOptional()
  latestFunctionalAreaPostId?: string;
}

export class GetBriefingPostDTO extends PagingDTO {
  @IsUUID()
  briefingId!: string;

  @IsInt()
  @IsOptional()
  days?: number;
}

/**
 * DeletePostsDTO should only contain one of the possible parameters.
 */
export class DeletePostsDTO {
  /**
   * Delete a specific post.
   */
  @IsUUID()
  @IsOptional()
  postId?: string;

  /**
   * Delete all posts which are somehow connected to the functionalArea.
   * This includes posts in a guide or department which are in this area.
   */
  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;
}
