import React from 'react';
import { useStores } from 'util/mobx/stores';
import { PackageDTO } from 'dto/package';
import HoverWrapper from 'components/HoverWrapper';
import Popover from 'components/Popover';
import GetCameraImageButton from 'components/GetCameraImageButton';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import MaterialValueTags from 'components/MaterialValueTags';
import Picture from 'components/Picture';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgerySingleInstrumentMenu from './SurgerySingleInstrumentMenu';
import SurgeryMaterialCheckItem from '../SurgeryMaterialCheckItem';

import './SurgerySingleInstrumentListItem.css';

interface Props extends OptionalLazyLoadProps {
  singleInstrument: PackageDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  onAddOneCheck: (packageId: string) => void;
  onRemoveOneCheck: (packageId: string) => void;
}

const SurgerySingleInstrumentListItem = ({
  singleInstrument,
  surgeryGuide,
  onAddOneCheck,
  onRemoveOneCheck,
  lazyLoadScrollContainer
}: Props) => {
  const { guideDetailDrawerStore, instrumentStore, surgeryGuideStore } = useStores();

  const onOpenDetails = () => {
    guideDetailDrawerStore.setSelectedPackage(singleInstrument);
    guideDetailDrawerStore.setSelectedSurgeryGuideItem(surgeryGuide);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const onUpdateFile = async (fileId: string) => {
    await instrumentStore.updateMaterial({ materialId: singleInstrument.instruments[0].material.materialId, pictureFileId: fileId });
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideStore.surgeryGuide.surgeryGuideId);
    }
  };

  const instrument = singleInstrument.instruments[0];
  const usedMaterial = surgeryGuide.usedMaterials.find(m => m.packageId === singleInstrument.packageId);

  const onStatusChange = (status: UsedMaterialStatus) => {
    if (status === UsedMaterialStatus.None) {
      onRemoveOneCheck(singleInstrument.packageId);
    } else {
      onAddOneCheck(singleInstrument.packageId);
    }
    surgeryGuideStore.setUsedMaterial({
      surgeryGuideMaterialId: surgeryGuide.surgeryGuideMaterialId,
      packageId: singleInstrument.packageId,
      status
    });
  };

  return (
    <HoverWrapper className="list_item_material surgery-package">
      {({ hover }) => (
        <>
          <div className="item_count _1_lvl ">
            <div className="item-count-single-instruments">{instrument.amount}</div>
          </div>
          <div className="item_dropdown_holder" onClick={onOpenDetails} />
          <div className="image_wrapper_50">
            <SurgeryMaterialCheckItem
              inSublist
              onStatusChanged={onStatusChange}
              status={usedMaterial?.status}
              surgeryGuide={surgeryGuide}
              usedMaterial={usedMaterial}
            />

            {singleInstrument.instruments[0].material.picture ? (
              <Picture
                withBorder
                size="pictureThumbnail"
                src={singleInstrument.instruments[0].material}
                width={50}
                alt=""
                lazyLoadScrollContainer={lazyLoadScrollContainer}
                className="image_circle_50"
              />
            ) : (
              <GetCameraImageButton buttonStyle="icon" description="" picturesOnly onUpdateFile={onUpdateFile} isAddable withLoadingBar />
            )}
            <div className="material_lvl1_line">
              <div className="dot in_mat_list" />
            </div>
          </div>

          <div className="material_info w-inline-block package-info">
            <div className="material_text material-text" onClick={onOpenDetails}>
              <div>{instrument.material.name}</div>
            </div>
            <MaterialValueTags pack={singleInstrument} onClick={onOpenDetails} />

            <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
              {({ handleClose, isOpen }) => (
                <SurgerySingleInstrumentMenu
                  isOpen={!!isOpen}
                  handleClose={handleClose}
                  surgeryGuide={surgeryGuide}
                  usedMaterial={usedMaterial}
                  onOpenDetails={onOpenDetails}
                />
              )}
            </Popover>
          </div>
        </>
      )}
    </HoverWrapper>
  );
};

export default SurgerySingleInstrumentListItem;
