import React from 'react';
import { MaterialDTO, MaterialType } from 'dto/material';
import { PackageDTO, PackagingType, SimplePackageDTO } from 'dto/package';
import { TemplateDTO } from 'dto/template';
import { InstrumentDTO } from 'dto/instrument';
import { MaterialSetDTO, SetType, BasicMaterialSetDTO } from 'dto/materialSet';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import ValueTag from 'components/ValueTag';
import { SetGroupDTO } from 'dto/setGroup';
import { getHoursTillNow } from 'util/date';

import './MaterialValueTags.css';
import { isERP } from 'util/materialUtils';
import { MaterialStorageLocationDTO } from 'dto/storageLocation';
import { useTranslation } from 'react-i18next';

interface Props {
  fullInfo?: boolean;
  material?: MaterialDTO;
  template?: TemplateDTO;
  pack?: PackageDTO;
  simplePack?: SimplePackageDTO;
  instrument?: InstrumentDTO;
  materialSet?: MaterialSetDTO | BasicMaterialSetDTO;
  setGroup?: SetGroupDTO;
  groupMaterial?: GroupMaterialDTO;
  materialStorageLocation?: MaterialStorageLocationDTO;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  classNameTitle?: string;
  classNameItem?: string;
}

const MaterialValueTags = ({
  fullInfo = false,
  material,
  template,
  pack,
  simplePack,
  instrument,
  materialSet,
  setGroup,
  groupMaterial,
  materialStorageLocation,
  onClick,
  classNameTitle = '',
  classNameItem = ''
}: Props) => {
  const { t } = useTranslation('materialTags');

  /**
   * buildTag creates a tag by title and value
   */
  function buildTag(title: string, value?: string, additionalItemClassName?: string, classNameValue?: string) {
    return (
      <ValueTag
        classNameTitle={classNameTitle}
        classNameItem={`${classNameItem} ${additionalItemClassName}`}
        classNameValue={classNameValue}
        title={title}
        value={value}
        onClick={onClick}
      />
    );
  }

  /**
   * buildTagIf & buildTypeTagIf only creates a tag if the condition is true. If not, it just returns an empty element.
   */
  function buildTagIf(condition: boolean, title: string, value?: string, additionalItemClassName?: string, classNameValue?: string) {
    return condition ? buildTag(title, value, additionalItemClassName, classNameValue) : <></>;
  }
  function buildTypeTagIf(condition: boolean, value?: string) {
    return condition ? buildTag('', value, 'maximal-width', 'capital-value') : <></>;
  }

  function buildManufacturerArticleNumber(m: MaterialDTO) {
    return buildTag(t('manufacturerArticleNumber'), m.manufacturerArticleNumber);
  }

  function buildERPId(m: MaterialDTO) {
    return buildTag(t('ERP ID'), m.erpId);
  }

  function buildSetIdentNumber(p: PackageDTO | SimplePackageDTO) {
    return buildTag(t('setIdentNr'), p.serialNumber);
  }

  function buildSetMaterialLastCheck(s: MaterialSetDTO | BasicMaterialSetDTO) {
    if (s.lastCheck === undefined) {
      return <></>;
    }
    const hours = getHoursTillNow(s.lastCheck);
    return buildTag(t('lastCheck'), hours.humanize(true), 'capital-first-letter');
  }

  function buildGroupExpectedCount(g: SetGroupDTO) {
    return buildTagIf(g.groupMaterials !== undefined, t('expected'), t('expectedValue', { value: g.groupMaterials.length }));
  }

  function buildStorageLocationItemsCount(g: MaterialStorageLocationDTO) {
    return buildTagIf(g.amount !== undefined, t('expected'), t('expectedValue', { value: g.amount }));
  }

  if (material) {
    return (
      <>
        {buildManufacturerArticleNumber(material)}
        {fullInfo ? buildERPId(material) : <></>}
        {buildTypeTagIf(material.materialType === MaterialType.material && isERP(material) && fullInfo, t('erpType'))}
        {buildTypeTagIf(material.materialType === MaterialType.material && !isERP(material) && fullInfo, t('singleMaterialType'))}
        {buildTypeTagIf(material.materialType === MaterialType.instrument && fullInfo, t('templateInstrumentType'))}
      </>
    );
  }

  if (template) {
    return <></>;
  }

  if (pack) {
    if (pack.packagingType === PackagingType.Single && pack.instruments[0]) {
      return (
        <>
          {buildSetIdentNumber(pack)}
          {buildManufacturerArticleNumber(pack.instruments[0].material)}
          {buildTypeTagIf(fullInfo, t('singleInstrumentType'))}
        </>
      );
    }
    // multi package
    return (
      <>
        {buildSetIdentNumber(pack)}
        {buildTypeTagIf(fullInfo, t('packageType'))}
      </>
    );
  }
  if (simplePack) {
    if (simplePack.packagingType === PackagingType.Single) {
      return (
        <>
          {buildSetIdentNumber(simplePack)}
          {buildTypeTagIf(fullInfo, t('singleInstrumentType'))}
        </>
      );
    }
    // multi package
    return (
      <>
        {buildSetIdentNumber(simplePack)}
        {buildTypeTagIf(fullInfo, t('packageType'))}
      </>
    );
  }

  if (instrument) {
    return (
      <>
        {buildManufacturerArticleNumber(instrument.material)}
        {buildTypeTagIf(fullInfo, t('templateInstrumentType'))}
      </>
    );
  }

  if (materialSet) {
    return (
      <>
        {buildSetMaterialLastCheck(materialSet)}
        {buildTypeTagIf(materialSet.type === SetType.Implants && fullInfo, t('materialSetImplantsType'))}
        {buildTypeTagIf(materialSet.type === SetType.Sets && fullInfo, t('materialSetsType'))}
        {buildTypeTagIf(materialSet.type === SetType.Medicals && fullInfo, t('materialSetMedicalsType'))}
        {buildTypeTagIf(materialSet.type === SetType.RentalMaterials && fullInfo, t('materialSetRentalMaterialsType'))}
      </>
    );
  }

  if (setGroup) {
    return <>{fullInfo ? buildGroupExpectedCount(setGroup) : <></>}</>;
  }

  if (groupMaterial) {
    return <>{buildManufacturerArticleNumber(groupMaterial.material)}</>;
  }

  if (materialStorageLocation) {
    return <>{buildStorageLocationItemsCount(materialStorageLocation)}</>;
  }

  return <></>;
};

export default MaterialValueTags;
